.table {
    border-collapse: separate;
    border-radius: 5px;
    overflow: hidden;
    font-size: 11px;
    text-align: center;
    width: 100%;
    height: auto;
    border: 0;
    border-spacing: 0;
}

.tdDark {
    position: relative;
    /*padding: 4px 2px 2px 2px;*/
    margin: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.tdDark,.tdDark p{
    color: #c2c6dc;
}

.tdLight {
    position: relative;
    /*padding: 4px 8px;*/
    margin: 0;
    border-bottom: 1px solid #c4c4c4;
    border-right: 1px solid #c4c4c4;
    color: #000000;
}

.line .td:before {
    content: " ";
    position: absolute;
    top: 55%;
    left: 0;
    border-bottom: 1px solid red;
    width: 100%;
    opacity: 0.7;
}

.theadDark {
    background: #212744;
}

.theadLight {
    background: #f8f8f8;
}

.th {
    padding: 5px;
    margin: 0;
    border-bottom: 1px solid #c4c4c4;
    border-right: 1px solid #c4c4c4;
}

.th:last-child {
    border-right: none;
}

table tr:last-child td {
    border-bottom: none;
}

/*table tbody th{*/
/*    box-shadow: rgb(0 0 0 / 5%) 0 4px 20px 0;*/
/*}*/

/*table tbody tr:hover{*/
/*    -webkit-transition: all .3s ease;*/
/*    -moz-transition: all .3s ease;*/
/*    -o-transition: all .3s ease;*/
/*    transition: all .3s ease;*/
/*    -webkit-transform: translateY(-4px);*/
/*    -moz-transform: translateY(-4px);*/
/*    -ms-transform: translateY(-4px);*/
/*    -o-transform: translateY(-4px);*/
/*    transform: translateY(-4px);*/
/*}*/

.tbodyLight {
    background: #fff;
}



.tbodyDark {
    background: #262c49;
}

.trDark:hover {
    background: #212744;
    cursor: pointer;
}

.trDark:focus {
    background: #212744;
}

.trLight:hover {
    background: #f8f8f8;
    cursor: pointer;
}

.trLight:focus {
    background: #f8f8f8;
}

/*.tdWithoutBorder, .thWithoutBorder{*/
/*    border-right: none;*/
/*}*/

.sort {
    width: 12px;
    float: right;
    opacity: 0.7;
    margin-top: 2px;
    display: none;
}

.main {
    overflow-x: auto;
    width: 100%;
    position: relative;
}