.upload-items {
  padding: 0 0 15px 12px;
}

.upload-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 15px 0 0 !important;
}

.upload-item label {
  height: unset;
}

.upload-item h5 {
  font-size: 13px;
}

.send-file button {
  margin-bottom: 0 !important;
  color: #ffffff !important;
  width: 100px;
  padding: 8px 0;
}

.light .ver-modal {
  background: #ffffff;
}

.dark .ver-modal {
  background: #10163a;
}

.ver-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  top: 0;
  left: 0;
  z-index: 11111;
  display: none;
}

.ver-title {
  padding-left: 20px;
}

/*.ver-modal .items{*/
/*    display: flex;*/
/*    width: 100%;*/
/*    margin-bottom: 20px;*/
/*    position: relative;*/
/*    flex-wrap: wrap;*/
/*}*/
/*.ver-modal .items p{*/
/*    font-size: 10px;*/
/*    width: 20px;*/
/*}*/
/*.ver-modal .items .item{*/
/*    width: calc(100% - 20px);*/
/*    display: flex;*/
/*    flex-wrap: wrap;*/
/*    justify-content: flex-start;*/
/*}*/

/*.ver-modal .items .item .add{*/
/*    width: 250px;*/
/*    margin-bottom: 10px;*/
/*    margin-right: 10px;*/
/*}*/

/*.ver-modal .items .item input{*/
/*    font-size: 10px;*/
/*    padding-top: 25px;*/
/*    height: fit-content;*/
/*}*/

/*.ver-modal .items .item label{*/
/*    font-size: 10px;*/
/*}*/

/*.ver-modal .items .item fieldset{*/
/*    height: 100%;*/
/*}*/

/*.ver-modal .items .button{*/
/*    margin: 0 auto;*/
/*}*/

.all-ver .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.verModal {
  position: fixed;
  width: 50vw;
  height: fit-content;
  min-height: 90vh;
  max-height: 90vh;
  overflow-y: scroll;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 20px;
  padding: 15px;
}

.verModalDark {
  background: #10163a;
}

.verModal .title {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-size: 22px;
  color: #000000;
}

.verModalDark .title {
  color: #ffffff;
}

.verModal .label {
  color: #000;
}

.verModalDark .label {
  color: #fff;
}

.verModal .inner {
  height: fit-content;
  max-height: 100%;
  width: 100%;
}

.verModalDark .inner input,
.verModalDark .inner label {
  color: #ffffff !important;
  -webkit-text-fill-color: #ffffff !important;
}

.verModalDark .inner svg {
  fill: #ffffff;
}

.supplier-form .modal-search-button {
  width: 100px;
  position: relative;
  z-index: 1;
}

.supplier-form .modal-search-button button {
  width: 100%;
  position: relative;
  right: 0;
}

.modal-search-button button img {
  margin-right: 3px;
}

.textArea {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.textArea textarea {
  resize: vertical;
  height: 40px;
  color: #000000;
}

.verModalLight .textArea span,
.verModalLight span {
  color: #000000 !important;
}

.verModalLight textarea {
  background: #ffffff;
  color: #000000;
  border: 1px solid #000000;
  resize: vertical;
}

.verModalDark textarea {
  color: #fff;
  background: #10163a;
  margin-bottom: 15px;
  border: 1px solid #ffffff;
  resize: vertical;
}

.verModal input[type="number"]::-webkit-outer-spin-button,
.verModal input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.modal-search-button .row {
  display: flex;
}

input[name="currencyTypeId"] {
  font-size: 12px;
}

input[name="founderName"] {
  text-transform: uppercase;
}

.supplier-filter-input:nth-child(4),
.supplier-filter-input:nth-child(5) {
  max-width: 12.5%;
  flex-basis: 12.5%;
}
.react-datepicker-wrapper {
  width: 120px !important;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  width: calc(100% - 20px);
  border: 0.5px solid grey;
}
.react-datepicker,
.react-datepicker .react-datepicker__header {
  background-color: #10163a !important;
  border-color: #fff !important;
}
.react-datepicker .react-datepicker__year-text,
.react-datepicker .react-datepicker__header {
  color: #fff !important;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  color: #000 !important;
}

@media screen and (max-width: 1440px) {
  .verModal {
    width: 70vw;
  }
}

@media screen and (max-width: 768px) {
  .verModal {
    width: 100vw;
    padding: 10px;
  }

  .upload-item {
    max-width: 30% !important;
    flex-basis: 30% !important;
  }
}

@media screen and (max-width: 575px) {
  .send-file button {
    width: 100%;
    margin: 5px 0;
  }

  .all-ver .top {
    flex-direction: column;
    align-items: flex-end;
  }

  .all-ver .top p {
    width: 100%;
    font-size: 18px;
    text-align: left;
  }

  .all-ver .top button {
    margin-left: auto;
    margin-bottom: 10px;
  }

  .verModal {
    width: 100vw;
    padding: 10px;
  }

  .verModal .title {
    font-size: 16px;
    padding-left: 10px;
  }

  .modal-search-button {
    left: 66%;
    top: 5px;
  }

  .upload-item {
    max-width: 45% !important;
    flex-basis: 45% !important;
  }
}

.founder-inputs .founder-title {
  display: none;
}

.founder-inputs .founder-title:first-child {
  display: block;
}

.founder-inputs .founder-input {
  margin-bottom: 5px;
}

.supplier-form .innInput,
.supplier-form .captchaInput {
  display: flex;
  align-items: center;
}

.supplier-form .innInput .modal-search-button {
  width: 40px;
  margin-left: 10px;
}

.supplier-form .fakeInput {
  opacity: 0;
  visibility: hidden;
}

.supplier-form .thirdLevelInput {
  width: calc(16.66666% - 19px);
}

.supplier-form .secondLevelInput,
.supplier-form .secondLevelInputWithBlock {
  width: calc(66.66666% - 7.5px);
}
.supplier-form .secondLevelInputWithBlock {
  display: flex;
}

.supplier-form .secondLevelInput .plus {
  display: none;
}

.supplier-form .gnkStatusInput {
  display: flex;
  grid-gap: 10px;
}

.supplier-form .gnkStatusInput > div button {
  position: absolute;
  display: flex;
  right: 0;
}

.supplier-form .gnkStatusInput > button {
  position: relative;
  width: 40px;
  right: 0;
}

.supplier-form .causeInput {
  margin-left: auto;
  margin-top: -126px;
}

.supplier-form .statusSbInput {
  margin-top: 40px;
}

.supplier-form .mfoInput {
  width: calc(16.66666% - 19px);
  display: flex;
}

.supplier-form .mfoInput .modal-search-button {
  width: 40px;
  margin-left: 10px;
}
