.vector {
    background: #000;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 8px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.vectorLight {
    background: rgba(0, 0, 0, 0);
    border: none;
    outline: none;
    cursor: pointer;
    padding: 8px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    box-shadow: -2px -2px 18px rgba(255, 255, 255, 0.15), 4px 4px 18px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(15px);
}

.chat {
    max-width: 136px;
    min-width: 110px;
    height: auto;
    background: #4839eb;
    padding: 8px 0px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0,0,0,.5);
    border: none;
    color: #ffffff !important;
    font-size: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.ndc {
    max-width: 136px;
    min-width: 110px;
    background: #1f9d57 ;
    padding: 8px 0;
    border-radius: 5px;
    border: none;
    color: #ffffff !important;
    font-size: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.lang {
    border: none;
    background: none;
    color: #71B6DD;
    cursor: pointer;
    font-size: 12px;
}

.addUser, .addApp {
    border: none;
    background: #1f9d57;
    color: #FFFFFF !important;
    padding: 8px 25px;
    border-radius: 5px;
    font-family: Montserrat, sans-serif;
    font-size: 13px;
    cursor: pointer;
}

.addApp{
    margin-left: auto;
}
.addApp a{
    color: #fff !important;
}

.add {
    background: #4839eb;
    border: none;
    border-radius: 10px;
    color: #fff !important;
    cursor: pointer;
    font-size: 13px;
    padding: 8px 15px;
    height: 38px;
}

.paymentCode {
    background: #212AFF;
    border: none;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    padding: 5px 12px;
    height: 32px;
    margin: 9px 0 0 9px;
}

.fleFormat {
    background: #7075FF;
    border: none;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    padding: 5px 12px;
    height: 38px;
    margin: 10px 0 0 10px;
}

.remove {
    background: #E32525;
    border: none;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    padding: 8px 15px;
    height: 38px;
}

.save {
    background: #4839eb;
    border: none;
    border-radius: 5px;
    color: #fff !important;
    cursor: pointer;
    font-size: 13px;
    padding: 8px 25px;
    margin: 5px;
}

.save:focus, .save:hover {
    background: #8689f8;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}

.cancel {
    background: #E32525;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    padding: 8px 25px;
    margin: 5px;
}

.cancel:focus, .cancel:hover {
    background: #f37b7b;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}

.reload {
    background: #f8a636;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    padding: 8px 25px;
    margin: 5px;
}

.submit {
    border: none;
    width: 216px;
    background: #7075FF;
    font-size: 12px;
    padding: 10px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    margin: 30px 22px;
}

.activity {
    max-width: 136px;
    min-width: 110px;
    background: #1f9d57;
    padding: 8px 0px;
    border-radius: 5px;
    box-shadow: 0px 0px 12px #1f9d57;
    border: none;
    color: #ffffff !important;
    font-size: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.search {
    background: #7075FF;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    padding: 8px 16px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 35px;
    align-items: center;
    text-align: center;
}

.searchPayment {
    background: #7075FF;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    padding: 8px 16px;
    display: flex;
    justify-content: space-around;
    height: 35px;
    align-items: center;
    text-align: center;
    margin-left: 10px;
}

.confirm {
    border: none;
    cursor: pointer;
    background: transparent;
}

.attach {
    width: 96%;
    border: 1px solid #1f9d57;
    height: 27px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    cursor: pointer;
    border-radius: 2px;
    font-size: 12px;
}

.attached {
    width: 96%;
    border: 1px solid #1689E8;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 2px;
    font-size: 12px;
}

.nds {
    background: #7075FF;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    padding: 8px 16px;
    display: flex;
    justify-content: space-around;
    height: 35px;
    align-items: center;
    text-align: center;
    margin-left: 20px;
}
.changeMenu {
    background: #7075FF;
    border: none;
    border-radius: 5px;
    color: #fff !important;
    cursor: pointer;
    font-size: 10px;
    padding: 0 10px;
    display: flex;
    justify-content: space-around;
    height: 25px;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    text-transform: uppercase;
    white-space: nowrap;
}
.filter{
    background: #8689f8;
    border-radius: 5px;
    color: #fff !important;
    cursor: pointer;
    font-size: 13px;
    padding: 8px 25px;
    margin-bottom: 30px;
    border: none;
}