.dark {
    background: #10163a;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);
}

.light {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 4px 25px 0 rgb(0 0 0 / 20%);
}

.light button{
    color: #FFFFFF !important;
}

.light p {
    font-size: 22px;
    line-height: 22px;
    color: #000 !important;
}

.dark p {
    font-size: 22px;
    line-height: 22px;
    color: #fff !important;
}

.topLight * {
    color: #000 !important;
}

.topDark * {
    color: #fff !important;
}

.action {
    text-align: right;
    margin-top: 40px;
}

.add {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 20px;
}

.save button, .plus button{
    color: #FFFFFF !important;
}

.TakeAction {
    display: flex;
    justify-content: space-around;
}

.hr {
    border: none;
    height: 1px;
    background: #fff;
    margin-top: 30px;
    opacity: 0.7;
}

.labelDark {
    font-size: 12px !important;
    color: #fff !important;
}

.labelLight {
    font-size: 12px !important;
    color: #000 !important;
}

.upload {
    background: #7075FF;
    border: none;
    border-radius: 5px;
    color: #fff !important;
    cursor: pointer;
    font-size: 13px;
    padding: 8px 16px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 35px;
    align-items: center;
    text-align: center;
}

.file {
    background: #7075FF;
    border: none;
    border-radius: 5px;
    color: #fff !important;
    cursor: pointer;
    font-size: 14px;
    padding: 8px 10px;
    display: flex;
    justify-content: space-around;
    height: 20px;
    align-items: center;
    text-align: center;
}

.fileUploaded {
    border: 1px solid #566EE9;
    border-radius: 5px;
    padding: 5px;
    font-size: 10px;
    margin: 10px;
}

.fileUploaded img {
    width: 20px;
    cursor: pointer;
}

.fileImgText {
    display: flex;
    align-items: center;
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fileUploaded img {
    margin-right: 10px;
}

.numbering {
    width: 20px !important;
    margin-top: 10px;
    margin-right: 15px;
    font-size: 14px !important;
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.changeAction {
    display: flex;
    justify-content: space-around;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.light .pagination li button{
    color: #000000 !important;
}
@media screen and (min-width: 960px) and (max-width: 1400px){
    .fileButton{
        max-width: 20%;
        flex-basis: 20%;
    }
    .addApplication{
        max-width: 33.33333%;
        flex-basis: 33.33333%;
    }
}