.activity {
    margin-left: auto !important;
}

.divider, .close, .listItemIcon {
    color: white !important;
}

.listItem {
    flex-direction: column;
}

.listItemText {
    display: flex;
    flex-direction: row;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 8px;
}

.text {
    color: silver;
    font-size: 15px;
    margin: 0;
}

.time {
    text-align: right;
    margin-left: auto;
}