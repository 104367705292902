.telegramContainer{
    position: relative;
    background: #10163a;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
.light{
    background: #fff;
}

.textarea{
    position: relative;
    width: 100%;
    color: #fff;
}
.light .textarea{
    background: #fff;
    border: 1px solid #EAEAEA;
    color: #000;
}
.telegramContainer button{
    margin-right: 0;
    margin-left: auto;
}
.list{
    width: 100%;
    min-height: 300px;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
}
.list::-webkit-scrollbar{
    width: 0;
}