
.pagination {
    margin-left: auto;
    margin-right: auto;
    color: #fff !important;
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.rootLight * {
    color: #000;
}

.rootLight li button{
    color: #000000 !important;
}

.rootDark * {
    color: #fff !important;
}

.rootDark button{
    border: 1px solid rgba(255,255,255, .2);
}

.rootDark .formControl * {
    color: #ffffff;
}

.formControlDark * {
    color: #FFFFFF !important;
}
.showing{
    display: flex;
    width: fit-content;
    align-items: center;
    padding: 0 10px;
    font-size: 14px;
}

