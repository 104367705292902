.light, .dark{
    box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);
}

.light {
    background: #fff;
    border-radius: 10px;
    padding: 15px;
}

.dark {
    background: #10163a;
    border-radius: 10px;
    padding: 15px;
}

.light, .dark p {
    font-size: 22px;
    line-height: 22px;
}

.light button{
    color: #FFFFFF !important;
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.applicationNumber {
    background: #3F85EE;
    border-radius: 3px;
    font-size: 12px !important;
    margin: 0;
    color: #FFFFFF !important;
}

.contractStatusNew {
    background: #00A3D7;
    border-radius: 3px;
    font-size: 12px !important;
    color: #FFFFFF !important;
    width: 94%;
    margin: 0 auto;
}

.contractStatusConfirm {
    background: #2AD43B;
    border-radius: 3px;
    font-size: 12px !important;
    margin: 0;
}
/* #ff9f43 */
.contractStatusAccepted {
    background: #3fcc3f;
    border-radius: 3px;
    font-size: 12px !important;
    width: 94%;
    margin: 0 auto;
    color: #FFFFFF !important;
}

.contractStatusRejected {
    background: #F41313;
    border-radius: 3px;
    font-size: 12px !important;
    color: #FFFFFF !important;
    width: 94%;
    margin: 0 auto;
}
/*fac000*/
.contractStatusRevision {
    background: #ff9f43;
    border-radius: 3px;
    font-size: 12px !important;
    color: #FFFFFF !important;
    width: 94%;
    margin: 0 auto;
}
/*ff9f43*/
.contractStatusSnab {
    background: #3fcc3f;
    border-radius: 3px;
    font-size: 12px !important;
    color: #FFFFFF !important;
    width: 94%;
    margin: 0 auto;
}

.paymentStatus {
    background: #7075FF;
    border-radius: 3px;
    font-size: 12px !important;
    margin: 0 auto;
    width: 85%;
}

/*Light*/
.contractStatusNewLight {
    background: #9ad0fd;
    border-radius: 3px;
    font-size: 12px !important;
    margin: 0;
}

.contractStatusConfirmLight {
    background: #8bff96;
    border-radius: 3px;
    font-size: 12px !important;
    margin: 0;
}
/*92f69d*/
.contractStatusAcceptedLight {
    background: #3fcc3f;
    border-radius: 3px;
    font-size: 12px !important;
    margin: 0;
}

.contractStatusRejectedLight {
    background: #f87f7f;
    border-radius: 3px;
    font-size: 12px !important;
    margin: 0;
    color: #FFFFFF !important;
}
/*fadd86*/
.contractStatusRevisionLight {
    background: #ff9f43;
    border-radius: 3px;
    font-size: 12px !important;
    margin: 0;
}
/*#ff9f43*/
/*#1f9d57*/
.contractStatusSnabLight {
    background: #3fcc3f;
    border-radius: 3px;
    font-size: 12px !important;
    margin: 0;
}

.paymentStatusLight {
    background: #adaffd;
    border-radius: 3px;
    font-size: 12px !important;
    margin: 0 auto;
    width: 85%;
}

.action {
    display: flex;
    justify-content: space-around;
}

.number {
    font-size: 12px !important;
}


.modal:after, .tableModal:after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(60deg, #4B74FF, #000, #fff);
    border-radius: calc(10 * var(--borderWidth));
    z-index: -1;
    animation: animatedGradient 3s ease alternate infinite;
    background-size: 300% 300%;
}

.tableModal {
    background: #10163a;
    padding: 23px;
    color: #fff;
    width: 25%;
    --borderWidth: 1px;
    position: relative;
    border-radius: 10px;
    margin-top: 5%;
    margin-left: 38%;
    min-height: 118px;
}

.labelLight {
    font-size: 12px !important;
    color: #000 !important;
}

.labelDark {
    font-size: 12px !important;
    color: #fff !important;
}

.tableModal h3 {
    font-size: 22px;
    margin-top: -10px;
}

.modalData {
    display: block;
    font-size: 15px;
}

.modalData img {
    margin-right: 5px;
}

.close {
    position: absolute;
    top: 23px;
    right: 23px;
    cursor: pointer;
}

.modalContacts img {
    display: block;
    margin-bottom: 10px;
}

.modalContacts {
    position: absolute;
    right: 20px;
}

.logo {
    position: absolute;
    right: 0;
    bottom: -5%;
    overflow-x: hidden;
}

.tooltip {
    margin-right: 3px;
    font-size: 12px;
}

.tooltipHeader {
    font-size: 16px;
    margin: 4px 10px 4px 10px;
}

.tooltipBody p {
    font-size: 12px;
    margin: 5px 15px 10px 15px;

}

.tooltipBody h5 {
    font-size: 12px;
    margin: 5px 15px;
    font-weight: lighter;
}

.xy {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.textArea {
    min-height: 80px;
    width: calc(100% - 22px);
    max-width: calc(100% - 22px);
    min-width: calc(100% - 22px);
    color: #FFFFFF;
}

textarea {
    background: #262c49;
    border: 1px solid #989CEF;
    border-radius: 5px;
    padding: 10px;
}

.light textarea{
    background: #FFFFFF;
}

.responsibleMain {
    background-color: #fa291b;
    width: 18px;
    height: 18px;
    border-radius: 32%;
    margin: auto;
    padding-top: 0;
    align-items: center;
    font-weight: 600;
    font-size: 12px !important;
}

.responsibleControl {
    background-color: #67A1F8;
    width: 18px;
    height: 18px;
    border-radius: 32%;
    margin: auto;
    padding-top: 0;
    align-items: center;
    font-weight: 600;
    font-size: 12px !important;
}

.responsibleAssistant {
    background-color: #2ad44f;
    width: 18px;
    height: 18px;
    border-radius: 32%;
    margin: auto;
    padding-top: 0;
    align-items: center;
    font-weight: 600;
    font-size: 12px !important;
}

.responsibleDefault {
    width: 18px;
    height: 18px;
    border-radius: 32%;
    margin: auto;
    padding-top: 3px;
    align-items: center;
    font-weight: 600;
    font-size: 12px !important;
}

.excel {
    font-size: 14px !important;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    background: #7367f0;
    color: #FFFFFF !important;
}

.excel img {
    margin-right: 5px;
}

.exportButton {
    margin-left: auto !important;
}

.confirmCode {
    display: flex;
    margin: 10px;
}

.countdown {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.onceMore {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.onceMore {
    margin-left: 10px;
}

.onceMore img {
    margin-right: 2px;
}

@keyframes animatedGradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@media screen and (min-width: 960px) and (max-width: 1400px){
    .tableModal{
        width: 60%;
        margin-left: calc(20% - 12px);
        padding: 24px;
    }
}

@media screen and (max-width: 768px){
    .exportButton{
        max-width: 100px !important;
        flex-basis: 100px !important;
    }
    .tableModal .textArea{
        margin-top: 10px;
    }
    .tableModal{
        width: 80%;
        margin-left: calc(10% - 12px);
        padding: 24px;
    }
}
@media screen  and (max-width: 575px) {
    .tableModal{
        width: calc(100% - 32px);
        padding: 15px;
        margin-left: 0;
    }
}