*:focus {
    outline: none;
}

.vector {
    margin-top: -15px;
    display: flex;
    margin-left: 0;
    cursor: pointer;
}

.arrowIcon{
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    border-radius: 6px;
    letter-spacing: -2px;
}
.arrowRightLight:before, .arrowRightLight:after, .arrowLeftLight:before, .arrowLeftLight:after{
    content: '';
    border: solid #9389f3;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
.arrowRightDark, .arrowLeftDark{
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.6);
}
.arrowRightDark:before, .arrowRightDark:after, .arrowLeftDark:before, .arrowLeftDark:after{
    content: '';
    border: solid #fff;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
.arrowLeftLight:before, .arrowLeftLight:after, .arrowLeftDark:before, .arrowLeftDark:after{
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.arrowDark:before{
    border: solid #fff;
}

.scroll {
    margin-top: -18px;
    width: 45%;
    height: 50px;
    overflow: hidden;
    display: flex;
}

.scrollbar {
    color: #fff;
    display: flex;
    align-items: center;
    padding: 15px;
    font-size: 12px;
}

.scrollbar p {
    margin-left: 5px;
}

.arrow {
    cursor: pointer;
}

.sideOws {
    text-align: center;
}

.leftMobileShift {
    display: none;
}

.sun {
    display: flex;
    align-items: center;
    margin-top: -15px;
    margin-right: 10px;
}

.mainOpen {
    display: flex;
    align-items: center;
    padding: 16px 0 10px 40px;
}

.mainOpen img {
    margin-right: 10px;
}

.mainClose {
    padding: 10px 0 0 30px;
}

.mainClose:hover .mainOpen {
    display: flex;
    align-items: center;
    padding: 16px 0 10px 40px;
    position: fixed;
    z-index: 1;
}


.mainClose img {
    display: block;
    padding: 2px;
    margin-left: -12px;
}

.mainClose img:nth-child(2) {
    margin-left: -24px;
    width: 80px;
}

.ows {
    font-family: Orbitron, sans-serif;
    font-size: 12px;
    color: #fff;
    position: absolute;
    top: 60px;
    left: 42px;
    letter-spacing: 0.09em;
}

.user {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    color: #fff;
    border-top: 1px solid rgba(0,0,0, .4);
    border-bottom: 1px solid rgba(0,0,0, .4);
    margin: 20px 0 0 0;
    cursor: pointer;
}

.user h5 {
    margin-left: 10px;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    line-height: 15px;
}

.user p {
    font-size: 9px;
    margin: -15px 0 0 10px;
    opacity: 0.5;
}

.lang {
    margin: 0;
    text-align: center;
    font-size: 12px;
}

.toolsOpen {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 12px;
    padding: 0 0 0 20px;
    cursor: pointer;
    border-left: 2px solid transparent;
    height: 31px;
    margin: 0 0 0 0;
}


.toolsOpen img {
    width: 16px;
}
.activeSideLight, .activeSideDark{
    box-shadow: 0 0 10px 1px rgb(115 103 240 / 70%);
    border-radius: 4px;
}

.activeSideDark {
    background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, .7));
    border-left: 2px solid #545BFF !important;
}
.activeSideDark p, .activeSideLight p{
    color: #fff;
}

.activeSideLight {
    background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, .7));
    border-left: 2px solid #545BFF !important;
}

.toolsOpen p {
    margin-left: 10px;
}

.toolsClose {
    border-left: 2px solid transparent;
    height: 31px;
    display: flex;
    cursor: pointer;
}

.toolsClose img {
    display: block;
    margin-left: 37px;
    width: 15px;
}

.anchor {
    margin: 8px 13px 0 auto;
}

.nested {
    display: flex;
    margin: 4px 0 0 20px;
    cursor: pointer;
    padding: 0 0 0 28px;
    height: 31px;
    align-items: center;
    font-size: 12px;
    border-radius: 4px;
}

.nested img {
    margin-right: 10px;
}

.nestedActiveLight p {
    color: #FFFFFF !important;
}

.nestedActiveDark {
    background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, .7));
    box-shadow: 0 0 10px 1px rgb(115 103 240 / 70%);
}

.nestedActiveLight {
    background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, .7));
    box-shadow: 0 0 10px 1px rgb(115 103 240 / 70%);
}

.badge {
    background-color: #7367f0;
    padding: 2px 6px;
    border-radius: 3px;
    margin: 0 20px 0 auto;
    font-size: 9px;
    color: #fff !important;
}

.badge:nth-child(1) {
    padding: 0 !important;
}

.modalDark {
    background: #10163a;
    padding: 20px;
    color: #fff;
    width: 20%;
    --borderWidth: 1px;
    position: relative;
    border-radius: 10px;
    margin-top: 3%;
    margin-left: 75%;
}

.modalLight {
    background: #fff;
    padding: 20px;
    color: #fff;
    width: 20%;
    --borderWidth: 1px;
    position: relative;
    border-radius: 10px;
    margin-top: 3%;
    margin-left: 75%;
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 10%);
}

.modalDark * {
    color: #fff !important;
}

.modalLight * {
    color: #000;
}
.modalLight img{
    filter: invert(1);
}
.modalDark p, .modalLight p {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.modalLight:after, .modalDark:after, .tableModalLight:after, .tableModalDark:after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(60deg, #4B74FF, #000, #fff);
    border-radius: calc(10 * var(--borderWidth));
    z-index: -1;
    animation: animatedGradient 3s ease alternate infinite;
    background-size: 300% 300%;
}

.modalDark img, .modalLight img {
    margin-right: 10px;
    width: 20px;
}

.modalLight input{
    color: #000000;
}

.modalLight svg path{
    color: #7367f0 !important;
}

.close {
    position: absolute;
    top: 23px;
    right: 23px;
    cursor: pointer;
}

.changePassword {
    margin: 0;
}

.action {
    text-align: right;
    margin-top: 10px;
}


.appbarButtomMobile {
    margin-top: 10px !important;
    margin-bottom: -10px !important;
}

.techPhone {
    padding: 8px;
    font-size: 12px;
    margin-top: auto;
}

.carousel img {
    min-height: 240px;
    max-height: 240px;
    object-fit: cover;
}

.ndsSum {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
}

.ndsSum p {
    margin-right: 10px;
}

@media (min-width: 1600px) {
    .scroll {
        width: 60%;
    }
}

@media (max-width: 960px) {
    .scroll {
        width: 100%;
        margin-top: 0;
    }

    .mainOpen {
        justify-content: center;
        padding: 15px 0;
    }

    .ows {
        width: 87%;
    }

    .leftMobileShift {
        display: inline;
        position: absolute;
        right: 5px;
        top: 10px;
    }
    .appbarButtomMobile{
        margin-bottom: 10px !important;
        width: 33.33333%;
    }
}
@media screen and (min-width: 960px) and (max-width: 1400px){
    .vector{
        margin-left: -5px !important;
    }
}
@media screen and (min-width: 575px) and (max-width: 768px){
    .leftMobileShift{
        display: none;
    }
    .modalDark, .modalLight{
        width: 40%;
        margin-left: 55%;
    }
}
@media screen  and (max-width: 575px){
    .appbarButtomMobile{
        margin-bottom: 5px !important;
        width: 50%;
    }
    .appbarButtomMobile:nth-child(3){
        width: 100%;
    }
    .appbarButtomMobile button{
        width: calc(100% - 5px) !important;
    }
    .appbarButtomMobile:nth-child(2) button{
        margin-left: auto;
        margin-right: 0;
    }
    .modalDark, .modalLight{
        width: calc(98vw - 30px);
        margin: 0;
        padding: 15px;
    }
}
@keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
.ndsModalLight .close{
    filter: unset;
}
.ndsSum button{
    color: #fff !important;
}
@media screen and (max-width: 768px){
    .ndsModal{
        width: 70% !important;
    }
    .ndsSum{
        padding-top: 15px;
    }
}
@media screen and (max-width: 575px){
    .ndsModal{
        width: calc(100% - 32px) !important;
        margin-bottom: 100px !important;
    }
    .ndsSum{
        flex-direction: column;
        align-items: flex-start;
    }
    .ndsSum button{
        margin-top: 10px;
        margin-left: 0;
    }
}
