.light, .dark{
    box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);
}

.dark {
    background: #10163a;
    border-radius: 10px;
    padding: 15px;
}

.light {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 15px;
}

.dark, .light p {
    font-size: 22px;
    line-height: 22px;
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TakeAction {
    display: flex;
    justify-content: space-around;
}

.checkbox {
    color: red !important;
}

.table {
    border-collapse: collapse;
    border-radius: 5px;
    overflow: hidden;
    font-size: 10px;
    text-align: center;
    width: 100%;
    height: auto;
}

.tdDark {
    position: relative;
    padding: 5px 10px;
    margin: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.tdLight {
    position: relative;
    padding: 5px 10px;
    margin: 0;
    border-bottom: 1px solid #C4C4C4;
    border-right: 1px solid #C4C4C4;
}

.line .td:before {
    content: " ";
    position: absolute;
    top: 55%;
    left: 0;
    border-bottom: 1px solid red;
    width: 100%;
    opacity: 0.7;
}

.theadDark {
    background: #212744;
}

.theadLight {
    background: #f8f8f8;
}

.th {
    padding: 10px;
    margin: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.th:last-child {
    border-right: none;
}

table tr:last-child td {
    border-bottom: none;
}

.tbodyLight {
    background: #fff;
}

.tbodyDark {
    background: #262c49;
}

.trDark:hover {
    background: #212744;
    cursor: pointer;
}

.trDark:focus {
    background: #212744;
}

.trLight:hover {
    background: #f8f8f8;
    cursor: pointer;
}

.trLight:focus {
    background: #f8f8f8;
}

.sort {
    width: 12px;
    float: right;
    opacity: 0.7;
    margin-top: 2px;
}

.main {
    overflow-x: auto;
    width: 100%;
    position: relative;
}
.resolutionTable{
    width: 100%;
}
.resolutionTableScroll{
    width: 100%;
    overflow: auto;
}