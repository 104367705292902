@import "./assets/fonts/fonts.css";
html, body {
    margin: 0;
    padding: 0;
    height: 100% !important;
    font-family: BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif,
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header{
    box-shadow: 0 4px 20px 0 rgba(0 0 0 / 20%) !important;
}

#dark {
    background-color: #10163a;
}

#light {
    background-color: #FFFFFF;
}

.user-dark-border{
    border-top: 1px solid rgba(255,255,255, .6) !important;
    border-bottom: 1px solid rgba(255,255,255, .6) !important;
}

::-webkit-scrollbar {
    width: 8px;
    background-color: #8FA7D7;
    height: 5px;
}

::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #8FA7D7;
}

::-moz-scrollbar {
    width: 4px;
    background-color: #ffffff;
}

::-moz-scrollbar-track {
    border-radius: 5px;
    background-color: #ffffff;
}

::-moz-scrollbar-thumb {
    border-radius: 5px;
    background-color: #dadada;
}
@media screen  and (max-width: 575px){
    ::-webkit-scrollbar{
        width: 0;
    }
    ::-moz-scrollbar{
        width: 0;
    }
}
.horizontal-content, .horizontal-content-dark, .horizontal-content-light{
    box-shadow: none;
}
.react-tabs__tab p{
    font-size: 12px;
    margin: 0;
    line-height: 22px;
}
.react-tabs__tab--selected{
    background: #1f9d57;
    border: none;
    box-shadow: none;
    outline: none;
}
.react-tabs__tab--selected:after{
    display: none;
}
.react-tabs__tab--selected p{
    color: #fff !important;
}

.react-tabs__tab-list{
    display: flex;
    border-bottom: 1px solid #1f9d57;
    margin-bottom: 25px;
}
.react-tabs-dark .white-text, .react-tabs-dark input{
    color: #fff;
}
.react-tabs-dark svg{
    fill: #fff;
}
.react-tabs-dark h5{
    color: #fff;
}
.file-buttons{
    display: flex;
    align-items: center;
}
.top-buttons{
    justify-content: flex-end;
}
.top-buttons button{
    padding: 0 10px !important;
    height: 31px !important;
    font-size: 12px !important;
    display: flex ;
    align-items: center;
    justify-content: center;
}
.file-button, .react-tabs .file-button button{
    padding: 0 5px;
    height: 30px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.file-button button{
    margin: 0 0 0 5px;
    padding: 0 5px;
    height: 30px;
}
.plus-buttons{
    display: flex;
    align-items: flex-start;
}
.plus-buttons button{
    width: 35px;
    height: 35px;
    padding: 0;
}
.plus-buttons button:first-child{
    margin-right: 5px;
}
.text-left{
    display: block;
    text-align: left !important;
    padding-left: 10px;
}
.text-right{
    display: block;
    text-align: right !important;
    padding-right: 10px;
}
.justify-start{
    justify-content: flex-start !important;
}
.founder{
    word-break: break-all;
    width: 270px;
    padding: 5px 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

}
.newTable tbody td{
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.newTable thead th{
    position: relative;
    border-right: none;
}
.newTable thead th:before{
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: rgba(255,255,255, .3);
}
iframe{
    display: none;
}
