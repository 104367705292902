.light {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 15px;
}

.dark {
    background: #10163a;
    border-radius: 10px;
    padding: 15px;
}

.light, .dark{
    box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);
}

.light, .dark p {
    font-size: 22px;
    line-height: 22px;
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TakeAction {
    display: flex;
    justify-content: space-around;
}
@media screen and (max-width: 768px){
    .addProductInput{
        max-width: 100% !important;
        flex-basis: 100% !important;
    }
}