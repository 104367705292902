.dark {
    background: #10163a;
    border-radius: 10px;
    padding: 15px;
}

.light {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 15px;
}

.light, .dark{
    box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);
}

.dark, .light p {
    font-size: 22px;
    line-height: 22px;
}

.labelDark {
    font-size: 12px !important;
    color: #fff !important;
}

.labelLight {
    font-size: 12px !important;
    color: #000 !important;
}

.colorAutocompleteDark * {
    color: #ffffff !important;
}

.colorAutocompleteLight * {
    color: #000 !important;
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TakeAction {
    display: flex;
    justify-content: space-around;
}