.light, .dark{
    box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);
}
.light {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 15px;
}

.dark {
    background: #10163a;
    border-radius: 10px;
    padding: 15px;
}

.light, .dark, .top {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-size: 22px;
}
.top{
    display: flex;
}

.top1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

h3 {
    margin-bottom: 10px !important;
}

.labelDark {
    font-size: 12px !important;
    color: #fff !important;
}

.labelLight {
    font-size: 12px !important;
    color: #000 !important;
}

.commercialOffer, .contract {
    width: 80%;
    height: 20px;
    border-radius: 3px;
    margin: auto;
    padding-top: 5px;
    align-items: center;
    font-weight: 600;
    font-size: 12px !important;
    border: 1px solid #ff9f43;
    background-color: transparent;
}


.nested {
    padding-left: 15px;
    padding-bottom: 10px;
}

.contract {
    border: 1px solid #00cfe8;
}

.deadlineOn, .deadlineOff {
    padding-top: 5px;
    color: #00FF0F;
}

.statusOn {
    width: 96%;
    height: 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: 1px solid #2AD43B;
    margin: 0 auto;
}

.statusOnLight {
    width: 96%;
    height: 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: 1px solid #2AD43B;
    margin: 0 auto;
}

.deadlineOff {
    background-color: #E32525 !important;
}

.gridLight, .gridDark {
    margin-bottom: 10px !important;
}

.gridLight * {
    color: #000 !important;
}

.gridLight button{
    color: #000000 !important;
}

.gridDark * {
    color: #fff !important;
}

/*********************  Modal  ************************/

.modal {
    background: #262c49;
    padding: 20px;
    color: #fff;
    width: 75%;
    --borderWidth: 1px;
    position: relative;
    border-radius: 10px;
    margin-left: 20%;
    margin-top: 5%;
}

.modalWarning{
    width: 30vw;
    height: fit-content;
    margin: auto;
    border-radius: 40px;
}
.paper{
    background: #262c49;
    padding: 30px;
    border-radius: 20px;
}
.paper button, .paper button:hover{
    display: block;
    text-transform: uppercase;
    margin: 5px auto;
    background: #4839eb;
}

.modalWarning p, .modalWarning h2{
    color: #FFFFFF;
}
.modalWarning p{
    font-size: 16px;
}
.modal p {
    font-size: 22px;
    font-family: Montserrat, sans-serif;
}

.form {
    width: 100%;
}

.modal:after, .tableModalDark:after, .tableModalLight:after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(60deg, #4B74FF, #000, #fff);
    border-radius: calc(10 * var(--borderWidth));
    z-index: -1;
    animation: animatedGradient 3s ease alternate infinite;
    background-size: 300% 300%;
}

.tableModalDark * {
    color: #fff !important;
}

.tableModalLight * {
    color: #000 !important;
}

.tableModalLight button{
    color: #FFFFFF !important;
}

.tableModalDark {
    background: #262c49;
    padding: 23px;
    color: #fff;
    width: 35%;
    --borderWidth: 1px;
    position: relative;
    border-radius: 10px;
    margin-top: 5%;
    margin-left: 32%;
}

.tableModalLight {
    background: #fff;
    padding: 23px;
    color: #fff;
    width: 35%;
    --borderWidth: 1px;
    position: relative;
    border-radius: 10px;
    margin-top: 5%;
    margin-left: 32%;
}

.userImage {
    border-radius: 50%;
    width: 80%;
    height: auto;
    /*height: auto;*/
}

.tableModalLight .userImage, .tableModalLight .close{
    filter: invert(1);
}

.tableModal h3 {
    font-size: 22px;
    margin-top: -10px;
}

.modalData {
    display: flex;
    flex-direction: column;
    font-size: 15px;
}

.modalData .userImage {
    margin: auto;
}

.modalInfo {
    display: flex;
}

.modalInfo img {
    margin-right: 8px;
}

.emailText {
    opacity: .5;
    margin-right: 5px;
}

.close {
    position: absolute;
    top: 23px;
    right: 23px;
    cursor: pointer;
}

.modalContacts img {
    display: block;
    cursor: pointer;
    position: relative;
    margin-bottom: 5px;
}

.modalContacts {
    position: absolute;
    right: 20px;
    top: 50px;
}

.logo {
    position: absolute;
    right: 0;
    bottom: -5%;
    overflow-x: hidden;
}

.moreButton {
    transform: rotate(90deg);
}

.moreDetails {
    padding: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    text-align: left;
    font-size: 12px;
}

.badge {
    margin-top: 5px;
    width: 100%;
}

.info {
    text-align: left;
    font-size: 15px !important;
    margin-left: 2.5%;
    margin-bottom: -5px;
}

.add {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add:nth-child(1) * {
    color: #fff;
    margin-bottom: 4px;
}

.action {
    display: flex;
    justify-content: space-around;
}

.action1 {
    text-align: right;
}

.penAndsee {
    display: flex;
    justify-content: space-around;
}

.hr {
    width: 100%;
    height: 1px;
    background: #fff;
    border: none;
    opacity: 0.7;
    margin-bottom: 20px;
}

.checkbox {
    margin-top: 20px;
}

.checkboxParent {
    display: flex;
    align-items: center;
}

.hr {
    width: 100%;
    opacity: 0.5;
    border: none;
    height: 1px;
    background: #E0F8FF;
}

.upload {
    background: #7075FF;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    padding: 8px 16px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 35px;
    align-items: center;
    text-align: center;
}

.file {
    background: #7075FF;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    padding: 8px 10px;
    display: flex;
    justify-content: space-around;
    height: 20px;
    align-items: center;
    text-align: center;
}

.fileUploaded {
    border: 1px solid #566EE9;
    border-radius: 5px;
    padding: 5px;
    font-size: 10px;
    margin: 10px;
}

.fileUploaded img {
    width: 20px;
    cursor: pointer;
}

.fileImgText {
    display: flex;
    align-items: center;
    width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

}

.fileUploaded img {
    margin-right: 10px;
}

.textArea {
    min-height: 80px;
    max-height: 200px;
    width: 300px;
    margin-left: 10px;
    min-width: 400px;
    max-width: 500px;
    color: #fff !important;
    margin-bottom: 15px;
}


.light .textArea textarea{
    background: #FFFFFF;
}

.percent {
    width: 30px;
    height: 20px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 3px;
    color: #000;
    background: transparent;
    margin-left: 10px;
}

.tooltip {
    margin-right: 3px;
    font-size: 12px;
}

.tooltipHeader {
    font-size: 16px;
    margin: 4px 10px 4px 10px;
}

.tooltipBody p {
    font-size: 12px;
    margin: 5px 15px 10px 15px;

}

.tooltipBody h5 {
    font-size: 12px;
    margin: 5px 15px;
    font-weight: lighter;
}

.xy {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}


.contractStatus {
    background: #2AD43B;
    border-radius: 3px;
    padding: 5px 8px;
    font-size: 12px !important;
    margin: 0;
}

.paymentStatus {
    padding: 5px 8px;
    background: #7075FF;
    border-radius: 3px;
    font-size: 12px !important;
    margin: 0 auto;
    width: 85%;
    color: #FFFFFF !important;
}


.applicationNumber {
    background: #3F85EE;
    border-radius: 3px;
    font-size: 12px !important;
    margin: 0;
    color: #FFFFFF !important;
}

.applicationNumberDefault {
    border-radius: 3px;
    font-size: 12px !important;
    margin: 0;
}

.number {
    font-size: 12px !important;
}

.attachButton {
    display: flex;
    margin: 0;
}

.attachButton img {
    margin-right: 5px;
    width: 14px;
}

.attachLabel {
    text-align: center;
}


.responsibleMain {
    background-color: #fa291b;
    width: 22px;
    height: 18px;
    border-radius: 32%;
    margin: auto;
    padding-top: 3px;
    align-items: center;
    font-weight: 600;
    font-size: 12px !important;
}

.responsibleControl {
    background-color: #67A1F8;
    width: 22px;
    height: 18px;
    border-radius: 32%;
    margin: auto;
    padding-top: 3px;
    align-items: center;
    font-weight: 600;
    font-size: 12px !important;
}

.responsibleAssistant {
    background-color: #2ad44f;
    width: 22px;
    height: 18px;
    border-radius: 32%;
    margin: auto;
    padding-top: 3px;
    align-items: center;
    font-weight: 600;
    font-size: 12px !important;
}

.responsibleDefault {
    width: auto;
    height: 18px;
    border-radius: 32%;
    margin: auto;
    padding-top: 3px;
    align-items: center;
    font-weight: 600;
    font-size: 12px !important;
}

.tableBodyLight * {
    color: #000 !important;
}

.tableBodyDark * {
    color: #fff !important;
}

.paymentType {
    color: #00A3D7 !important;
    font-size: 12px !important;
}

.contractStatusNew, .contractStatusNewLight {
    background: #00A3D7;
    border-radius: 3px;
    font-size: 12px !important;
    padding: 5px 8px;
    color: #FFFFFF !important;
}

.contractStatusConfirm, .contractStatusConfirmLight {
    background: #2AD43B;
    border-radius: 3px;
    font-size: 12px !important;
    padding: 5px 8px;
    color: #FFFFFF !important;
}

.contractStatusAccepted, .contractStatusAcceptedLight {
    background: #3fcc3f;
    border-radius: 3px;
    font-size: 12px !important;
    padding: 5px 8px;
    color: #FFFFFF !important;
}

.contractStatusRejected, .contractStatusRejectedLight {
    background: #F41313;
    border-radius: 3px;
    font-size: 12px !important;
    padding: 5px 8px;
    color: #FFFFFF !important;
}

.contractStatusRevision, .contractStatusRevisionLight {
    background: #ff9f43;
    border-radius: 3px;
    font-size: 12px !important;
    padding: 5px 8px;
    color: #FFFFFF !important;
}

.contractStatusSnab, .contractStatusSnabLight {
    background: #3fcc3f;
    border-radius: 3px;
    font-size: 12px !important;
    padding: 5px 8px;
    color: #FFFFFF !important;
}

@keyframes animatedGradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
.tableModalLight fieldset{
    border-color: #000000 !important;
}
.attachInput div[role="button"]{
    height: 40px;
    padding: 0 0 0 10px;
    display: flex;
    align-items: center;
}
.law{
    padding: 15px;
    border: 1px solid rgba(255,255,255,.5);
}
.light .law{
    border: 1px solid rgba(0,0,0,.5);
}
.law p{
    font-size: 14px;
    line-height: 24px;
    font-style: italic;
}
.gridLight .law{
    border: 1px solid rgba(0,0,0,.5);
}
@media screen and (min-width: 960px) and (max-width: 1400px){
    .tableModalLight, .tableModalDark{
        width: 80% !important;
        margin-left: auto !important;
        margin-right: auto;
    }
}
@media screen and (max-width: 768px){
    .tableModalLight, .tableModalDark{
        width: calc(100% - 50px) !important;
        margin-left: auto !important;
        margin-right: auto !important;
        margin-bottom: 100px !important;
    }
    .textArea{
        min-width: 50%;
        max-width: 100%;
    }
    .attachInput{
        max-width: 33.33333% !important;
        flex-basis: 33.33333% !important;
    }
    .attachInput div{
        width: 100% !important;
    }
    .addContactInput{
        max-width: 33.55555% !important;
        flex-basis: 33.33333% !important;
    }
    .addContractSearchBtn{
        max-width: 100px !important;
        flex-basis: 100px !important;
    }
}
@media screen and (max-width: 575px){
    .attachInputs{
        margin-bottom: 20px;
    }
    .attachInput{
        max-width: 100% !important;
        flex-basis: 100% !important;
        margin-bottom: 10px !important;
    }
    .add{
        flex-direction: column-reverse;
    }
    .addContactInput{
        max-width: 100% !important;
        flex-basis: 100% !important;
    }
    .addContactFile{
        flex-direction: column-reverse;
        position: relative;
    }
    .addContactFileInner{
        position: relative;
        max-width: 100% !important;
        flex-basis: 100% !important;
        margin-top: 0 !important;
    }
    .addContactFileInner textarea{
        width: 90%;
        margin: 10px 0 15px 0;
        resize: none;
    }
    .top1{
        flex-direction: column-reverse;
    }
    .addContactFileBtn{
        max-width: 150px !important;
        flex-basis: 150px !important;
    }
}
