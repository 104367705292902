.footer{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: rgb(0, 42, 71);
    z-index: 1300;
}
.footer .item{
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer .line{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 30px 0;
}
.footer .line:before{
    content: "";
    width: 1px;
    height: 40px;
    background-color: #fff;
    position: absolute;
}
.footer h3{
    margin-top: 0;
}
.footer .logo{
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer .logo img{
    width: 100%;
}
.footer p{
    padding: 0;
    margin: 0;
    width: 90%;
    font-size: 12px;
    color: #fff;
}