*:focus {
    outline: none;
}

.scroll {
    margin-top: -7px;
    width: 101%;
    height: auto;
    margin-left: -5px;
}

.light {
    color: #fff;
    padding: 15px;
    font-size: 12px;
    margin-right: 7px;
    backdrop-filter: blur(15px);
    background: #E5EAF1;
    width: 279px;
    height: 135px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.dark {
    color: #fff;
    padding: 15px;
    font-size: 12px;
    margin-right: 7px;
    backdrop-filter: blur(15px);
    background: #000;
    width: 279px;
    height: 135px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.back {
    position: absolute;
    left: 0;
    bottom: 0;
    border-bottom-left-radius: 15px;
}

.back:hover {
    transform: scale(1) !important;
}

.light img {
    margin-left: auto;
    display: flex;
}

.dark img {
    margin-left: auto;
    display: flex;
}

.light img:hover {
    transform: scale(1.07);
    transition: transform 0.3s ease;
}

.light h1 {
    font-size: 40px;
    margin: 25px 0 -10px 0;
}

.light p {
    margin-left: 5px;
}

.dark img:hover {
    transform: scale(1.07);
    transition: transform 0.3s ease;
}

.dark h1 {
    font-size: 40px;
    margin: 25px 0 -10px 0;
}

.dark p {
    margin-left: 5px;
}

@media (max-width: 960px) {
    .light {
        margin-top: 10px;
    }

    .dark {
        margin-top: 10px;
    }
}