.inputDark[type=checkbox] {
    position: relative;
    cursor: pointer;
    margin-bottom: 5px;
}

.inputDark[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    min-width: 14px;
    min-height: 14px;
    top: -1px;
    left: -2px;
    background-color: #FFFFFF;
    border: 1px solid #7367f0;
    border-radius: 3px;
}

.inputDark[type=checkbox]:checked:before {
    content: "";
    display: block;
    position: absolute;
    min-width: 14px;
    min-height: 14px;
    top: -1px;
    left: -2px;
    background-color: #7367f0;
}

.inputDark[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 4px;
    height: 10px;
    border: solid #FFFFFF;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    border-radius: 2px;
    top: 0;
    left: 3px;
}

.header {
    font-size: 12px !important;
    margin: 0;
}

.label {
    margin-left: 10px;
    cursor: pointer;
}