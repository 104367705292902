.light, .dark, .modalLight, .modalDark{
    box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);
}

.dark {
    background: #10163a;
    border-radius: 10px;
    padding: 15px;
}

.light {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 15px;
}

.dark, .light p {
    font-size: 22px;
    line-height: 22px;
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.labelDark {
    font-size: 12px !important;
    color: #fff !important;
}

.labelLight {
    font-size: 12px !important;
    color: #000 !important;
}

.colorAutocompleteDark * {
    color: #ffffff !important;
}

.colorAutocompleteLight * {
    color: #000 !important;
}

/*---------------------------------------------------*/
.modalDark {
    background: #10163a;
    padding: 20px;
    color: #fff;
    width: 75%;
    --borderWidth: 1px;
    position: relative;
    border-radius: 10px;
    margin-left: 20%;
    margin-top: 5%;
}

.modalLight {
    background: #FFFFFF;
    padding: 20px;
    color: #fff;
    width: 75%;
    --borderWidth: 1px;
    position: relative;
    border-radius: 10px;
    margin-left: 20%;
    margin-top: 5%;
}

.modalDark * {
    color: #fff !important;
}

.modalLight * {
    color: #000 !important;
}

.modalDark, .modalLight p {
    font-size: 22px;
    line-height: 27px;
    font-family: Montserrat, sans-serif;
}

.modalDark:after, .modalLight:after, .tableModal:after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(60deg, #4B74FF, #000, #fff);
    border-radius: calc(10 * var(--borderWidth));
    z-index: -1;
    animation: animatedGradient 3s ease alternate infinite;
    background-size: 300% 300%;
}

/*--------------------------------*/
.large {
    width: 140px !important;
    height: 140px !important;
    z-index: 0;
    object-fit: cover;
}

.circle {
    width: 140px;
    height: 140px;
    border-radius: 75px;
    background-size: cover;
    overflow: hidden;
    position: relative;
    background-color: #CCCCCC;
    border: 1px solid #ccc;
}

.overlay {
    position: absolute;
    top: 100%;
    left: 0;
    padding-top: 10px;
    text-align: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    transition: top 0.5s;
}

.filter {
    display: flex;
}

.add {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 20px;
}

.circle:hover .overlay {
    top: 0 !important;
}

.overlay img {
    margin: 50px -20px -20px;
}

.camera {
    cursor: pointer;
}

.contacts {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contacts img {
    width: 22px;
    margin-right: 10px;
}

.addPlus {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 15px;
}

.action {
    text-align: right;
}

.action1 {
    text-align: right;
    margin-top: 20px;
    margin-left: auto;
}

/*------------------------------------------Table*/
.TakeAction {
    display: flex;
    justify-content: space-around;
}

/*------------------------*/
.tableModal {
    background: #10163a;
    padding: 23px;
    color: #fff;
    width: 35%;
    --borderWidth: 1px;
    position: relative;
    border-radius: 10px;
    margin-top: 5%;
    margin-left: 32%;
    min-height: 118px;
}

.tableModal h3 {
    font-size: 22px;
    margin-top: -10px;
}

.modalData {
    display: flex;
    align-items: center;
    font-size: 15px;
}

.modalData img {
    margin-right: 5px;
}

.close {
    position: absolute;
    top: 23px;
    right: 23px;
    cursor: pointer;
}

.modalContacts img {
    display: block;
    margin-bottom: 10px;
}

.modalContacts {
    position: absolute;
    right: 20px;
}

.logo {
    position: absolute;
    right: 0;
    bottom: 0;
}

/*--------------------------------------------*/
.input {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.textField {
    margin-top: 15px;
}

.hr {
    opacity: 0.5;
    border: 1px solid #E0F8FF;
    color: #E0F8FF;
    margin-bottom: 20px;
    margin-top: 15px;
}

.map {
    margin-bottom: 15px
}

.tooltip {
    margin-right: 3px;
    font-size: 10px;
}

@keyframes animatedGradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.fullName {
    font-size: 12px !important;
    margin: 0;

}

.org {
    display: inline-block;
    width: 80px;
    white-space: nowrap;
}

.gridContainer {
    margin-top: 30px;
}
@media screen  and (min-width: 575px) and (max-width: 768px){
    .modalDark, .modalLight, .tableModal{
        margin-right: auto;
        margin-left: auto;
    }
}
@media screen  and (max-width: 575px){
    .modalDark, .modalLight, .tableModal{
        width: calc(100vw - 30px);
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
    }
}