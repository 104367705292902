.header{
  width: calc(100vw - 10px);
  position: relative;
  .top-bar-mode{
    filter: brightness(0) invert(1);
  }
  .inner{
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    position: relative;
    .top{
      display: flex;
      padding: 0 30px;
      background: #1c1c1c;
    }
    .left{
      width: 100%;
      height: 50px;
      overflow: hidden;
      display: flex;
      .title{
        background: #1c1c1c;
        color: #fff;
      }
      .scrollbar {
        color: #fff;
        display: flex;
        align-items: center;
        padding: 10px;
        font-size: 12px;
        p{
          margin-left: 5px;
          color: #fff;
        }
      }
    }
    .right{
      padding-left: 30px;
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      z-index: 2;
      .language{
        display: flex;
        justify-content: center;
        background: #1c1c1c;
        button{
          p{
            color: #fff;
          }
        }
      }
      .item{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px;
        cursor: pointer;
        .user{
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 5px;
          cursor: pointer;
          span div{
            width: 30px;
            height: 30px;
          }
        }
        .icon{
          width: 20px;
          height: 20px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: 100%;
            filter: invert(1);
          }
          .counter{
            position: absolute;
            top: -5px;
            right: -4px;
            width: 15px;
            height: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 10px;
            font-weight: 500;
            color: #fff;
            background: #f62718;
          }
        }
        .avatar{
          width: 25px;
          height: 25px;
          border-radius: 50%;
          filter: none;
        }
        .name{
          padding-left: 10px;
          font-size: 12px;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-weight: bolder;
          white-space: nowrap;
          span{
            font-size: 8px;
            font-weight: normal;
            color: #bdbdbd;
          }
        }
      }
    }
  }
  .menu{
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(0,0,0,.2);
    width: 100%;
    background: rgba(14, 14, 14, .8);
    .links{
      width: 7.1%;
      height: 80px;
      .link{
        width: 100%;
        height: 70px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 6px 0;
        text-align: center;
        border-left: 1px solid rgba(0,0,0,.2);
        box-shadow: inset 0px -6px 10px -4px rgba(34, 60, 80, 0.3);
        p{
          margin: 10px 0 0 0;
          font-size: 12px;
          color: #fff;
        }
        img{
          width: 30px;
          height: 30px;
        }
        &-active{
          img{
            filter: brightness(0) invert(1);
          }
        }
      }
      &:first-child{
        .link{
          border-left: none;
        }
      }
      &:last-child{
        border-right: none;
      }
      .dropdown{
        position: absolute;
        top: 100%;
        left: 0;
        width: calc(100% - 28px);
        padding-left: 30px;
        a{
          display: inline-block;
        }
        .nested{
          display: flex;
          width: fit-content;
          margin: 7px;
          cursor: pointer;
          padding: 6px 10px;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          border-radius: 25px;
          border: 1px solid transparent;
          &:hover{
            border: 1px solid #fff;
          }
          &-active{
            border: 1px solid #fff;
            box-shadow: 0px 0px 18px 0px rgba(50, 50, 50, .87);
          }
          p{
            color: #fff;
            margin: 0;
          }
          .badge{
            margin: 0 0 0 10px;
            background-color: #c72424;
            border-radius: 8px;
          }
        }
      }
    }
    .users{
      border-bottom: 4px solid #39badf;
      &-sub, &-active,&:hover{
        background: #39badf;
        img{
          filter: brightness(0) invert(1);
        }
      }
    }
    .organizations{
      border-bottom: 4px solid #faa502;
      &-sub, &-active,&:hover{
        background: #faa502;
        img{
          filter: brightness(0) invert(1);
        }
      }
    }
    .applications{
      border-bottom: 4px solid #4f74fc;
      &-sub, &-active,&:hover{
        background: #4f74fc;
        img{
          filter: brightness(0) invert(1);
        }
      }
    }
    .contracts{
      border-bottom: 4px solid #27b732e6;
      &-sub, &-active,&:hover{
        background: #27b732e6;
        img{
          filter: brightness(0) invert(1);
        }
      }
    }
    .counterparties{
      border-bottom: 4px solid #efca02;
      &-sub, &-active,&:hover{
        background: #efca02;
        img{
          filter: brightness(0) invert(1);
        }
      }
    }
    .tender{
      border-bottom: 4px solid #d06084;
      &-sub, &-active,&:hover{
        background: #d06084;
        img{
          filter: brightness(0) invert(1);
        }
      }
    }
    .schedule{
      border-bottom: 4px solid #3eb7a2;
      &-sub, &-active,&:hover{
        background: #3eb7a2;
        img{
          filter: brightness(0) invert(1);
        }
      }
    }
    .products{
      border-bottom: 4px solid #8E3ADF;
      &-sub, &-active,&:hover{
        background: #8E3ADF;
        img{
          filter: brightness(0) invert(1);
        }
      }
    }
    .suppliers{
      border-bottom: 4px solid #e27d60;
      &-sub, &-active,&:hover{
        background: #e27d60;
        img{
          filter: brightness(0) invert(1);
        }
      }
    }
    .objects{
      border-bottom: 4px solid #8589f1;
      &-sub, &-active,&:hover{
        background: #8589f1;
        img{
          filter: brightness(0) invert(1);
        }
      }
    }
    .estimate{
      border-bottom: 4px solid #e8a87c;
      &-sub, &-active,&:hover{
        background: #e8a87c;
        img{
          filter: brightness(0) invert(1);
        }
      }
    }
    .warehouse{
      border-bottom: 4px solid #e98074;
      &-sub, &-active,&:hover{
        background: #e98074;
        img{
          filter: brightness(0) invert(1);
        }
      }
    }
    .reports{
      border-bottom: 4px solid #41b3a3;
      &-sub, &-active, &:hover{
        background: #41b3a3;
        img{
          filter: brightness(0) invert(1);
        }
      }
    }
    .settings{
      border-bottom: 4px solid #e4322f;
      &-sub, &-active,&:hover{
        background: #e4322f;
        img{
          filter: brightness(0) invert(1);
        }
      }
    }
  }
  .date{
    color: #fff;
  }
}

.topnav {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 110px;
}
.topnav-search {
  position: relative;
  height: 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  box-shadow: 0 8px 24px  rgba(149, 157, 165, 0.2);
  border-radius: 15px;
  overflow: hidden;
  >input {
    height: 100%;
    width: 100%;
    padding: 10px 60px 10px 20px;
    font-size: 1rem;
    border-radius: 15px;
    color: #455560;
    background-color: #fff;
  }
  >i {
    font-size: 1.5rem;
    position: absolute;
    right: 20px;
  }
}
.topnav-right {
  display: flex;
  align-items: center;
}
.topnav-right-item {
  ~ {
    .topnav-right-item {
      margin-left: 30px;
    }
  }
}
.notification-item {
  display: flex;
  align-items: center;
  padding: 20px;
  &:hover {
    background-color: #fafafb;
  }
  >i {
    margin-right: 20px;
    font-size: 1.5rem;
  }
}
.topnav-right-user {
  display: flex;
  align-items: center;
}
.topnav-right-user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  >img {
    width: 100%;
  }
}
.topnav-right-user-name {
  font-size: 1rem;
  font-weight: 600;
}
