.secure-page {
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 30px 0;
  position: fixed;
  background: #fff;
  z-index: 10;
  left: 0;
  top: 0;
  overflow-y: scroll;
}
.secure-page .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.secure-page .content {
  width: 200px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  background: #f8f8f8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px;
  margin: 0 auto;
}
.secure-page .content h2 {
  margin: 0;
  color: #000;
}
.secure-page .content img {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}
.secure-page .content span {
  color: #111;
  font-size: 16px;
}
.secure-page .content input {
  height: 25px;
  outline: none;
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  color: #000;
  padding: 0 10px;
  margin: 10px 0;
  text-align: center;
}
.secure-page .content button {
  outline: none;
  border: none;
  height: 30px;
  padding: 0 10px;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  background: #4839eb;
  border-radius: 4px;
}
