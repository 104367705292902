@font-face {
    font-family: "EirenSans Regular";
    src: url('EireneSans-Regular.otf') format("opentype");
    font-display: swap;
}
@font-face {
    font-family: "Comfortaa";
    src: url('Comfortaa.ttf') format("truetype");
    font-display: swap;
}
@font-face {
    font-family: "Roboto";
    src: url('Roboto.ttf') format("truetype");
    font-display: swap;
}