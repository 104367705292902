.light {
    background: #fff;
    border-radius: 10px;
    padding: 15px;
}

.dark {
    background: #10163a;
    box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);
    border-radius: 10px;
    padding: 15px;
}

.light, .dark{
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 5%);
}

.light, .dark p {
    font-size: 22px;
    /*line-height: 22px;*/
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.label {
    font-size: 12px !important;
    color: #fff !important;
}

.colorAutocompleteDark * {
    color: #ffffff !important;
}

.colorAutocompleteLight * {
    color: #000 !important;
}

/*---------------------------------------------------*/
.modalDark {
    background: #262c49;
    padding: 20px;
    color: #fff;
    width: 75%;
    --borderWidth: 1px;
    position: relative;
    border-radius: 10px;
    margin-left: 20%;
    margin-top: 5%;
}

.modalLight {
    background: #FFFFFF;
    padding: 20px;
    color: #fff;
    width: 75%;
    --borderWidth: 1px;
    position: relative;
    border-radius: 10px;
    margin-left: 20%;
    margin-top: 5%;
}
.modalLight img{
    filter: invert(1);
}
.modalDark * {
    color: #fff !important;
}

.modalLight * {
    color: #000 !important;
}

.modalLight button{
    color: #FFFFFF !important;
}

.modalLight, .modalLight p {
    /*font-size: 22px;*/
    line-height: 27px;
    font-family: Montserrat, sans-serif;
}

.modalLight:after, .modalDark:after, .tableModalLight:after, .tableModalDark:after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(60deg, #4B74FF, #000, #fff);
    border-radius: calc(10 * var(--borderWidth));
    z-index: -1;
    animation: animatedGradient 3s ease alternate infinite;
    background-size: 300% 300%;
}

/*--------------------------------*/
.large {
    width: 140px !important;
    height: 140px !important;
    z-index: 0;
    object-fit: cover;
}

.circle {
    width: 140px;
    height: 140px;
    border-radius: 75px;
    background-size: cover;
    overflow: hidden;
    position: relative;
    background-color: #CCCCCC;
    border: 1px solid #ccc;
}

.overlay {
    position: absolute;
    top: 100%;
    left: 0;
    padding-top: 10px;
    text-align: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    transition: top 0.5s;
}

.filterLight, .filterDark {
    display: flex;
    margin-bottom: 15px !important;
}

.filterLight * {
    color: #000 !important;
}

.filterDark * {
    color: #fff !important;
}

.add {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 20px;
}

.circle:hover .overlay {
    top: 0 !important;
}

.overlay img {
    margin: 50px -20px -20px;
}

.camera {
    cursor: pointer;
}

.contacts {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contacts img {
    width: 22px;
    margin-right: 10px;
}

.tableModalLight .contacts img{
    filter: invert(1);
}

.addPlus {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 15px;
}

.action {
    text-align: right;
}

.action1 {
    text-align: right;
    margin-top: 20px;
    margin-left: auto;
}

/*------------------------------------------Table*/
.TakeAction {
    display: flex;
    justify-content: space-around;
}

/*------------------------*/
.tableModalDark {
    background: #262c49;
    padding: 23px;
    color: #fff;
    width: 35%;
    --borderWidth: 1px;
    position: relative;
    border-radius: 10px;
    margin-top: 5%;
    margin-left: 32%;
    min-height: 118px;
}

.tableModalLight {
    background: #FFFFFF;
    padding: 23px;
    color: #fff;
    width: 35%;
    --borderWidth: 1px;
    position: relative;
    border-radius: 10px;
    margin-top: 5%;
    margin-left: 32%;
    min-height: 118px;
}

.tableModalLight h3, .tableModalDark h3 {
    font-size: 22px;
    margin-top: -10px;
}

.tableModalLight .close{
    filter: invert(1);
}

.tableModalDark * {
    color: #fff !important;
}

.tableModalLight * {
    color: #000 !important;
}

.tableModalLight button{
    color: #FFFFFF !important;
}

.modalData {
    display: flex;
    align-items: center;
    font-size: 15px;
}

.modalData img {
    margin-right: 5px;
}

.close {
    position: absolute;
    top: 23px;
    right: 23px;
    cursor: pointer;
}

.modalContacts img {
    display: block;
    margin-bottom: 10px;
}

.modalContacts {
    position: absolute;
    right: 20px;
}

.logo {
    position: absolute;
    right: 0;
    bottom: 0;
}

/*--------------------------------------------*/
.input {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.textField {
    margin-top: 15px;
}

.hr {
    opacity: 0.5;
    border: 1px solid #E0F8FF;
    color: #E0F8FF;
    margin-bottom: 20px;
    margin-top: 15px;
}

.map {
    margin-bottom: 15px
}

.tooltip {
    margin-right: 3px;
    font-size: 10px;
}

.upload {
    background: #7075FF;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    padding: 8px 16px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 35px;
    align-items: center;
    text-align: center;
}

.file {
    background: #7075FF;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    padding: 8px 10px;
    display: flex;
    justify-content: space-around;
    height: 20px;
    align-items: center;
    text-align: center;
}

.fileUploaded {
    border: 1px solid #566EE9;
    border-radius: 5px;
    padding: 5px;
    font-size: 10px;
    margin: 10px;
}

.fileUploaded img {
    width: 20px;
    cursor: pointer;
}

.fileImgText {
    display: flex;
    align-items: center;
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fileUploaded img {
    margin-right: 10px;
}

.textArea {
    min-height: 80px;
    max-height: 150px;
    width: 300px;
    margin-left: 10px;
    min-width: 400px;
    max-width: 500px;
}

.applicationNumber {
    background: #3F85EE;
    border-radius: 3px;
    font-size: 12px !important;
    margin: 0 auto;
    width: 94%;
    color: #FFFFFF !important;
}

.applicationNumberLight{
    background: #bed4ff;
    border-radius: 3px;
    font-size: 12px !important;
    margin: 0;
}

.numbering {
    width: 20px!important;
    margin-top: 10px;
    margin-right: 15px;
    font-size: 14px!important;
}

.activate, .deactivate{
    font-size: 12px!important;
    width: 80%;
    height: 20px;
    display: flex;
    justify-content: center;
    margin: 0 auto 0 auto;
    border-radius: 2px;
    cursor: pointer;
    align-items: center;
}

.activate {
    border: 1px solid  #28c76f;
    color: #28c76f;
}

.deactivate {
    border: 1px solid #babfc7;
    color: #babfc7;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

textarea {
    background: #2B2B2B;
    border: 1px solid #989CEF;
    border-radius: 5px;
    padding: 10px;
}

@keyframes animatedGradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.fullName {
    font-size: 12px !important;
    margin: 0;

}
.org {
    display: inline-block;
    width: 80px;
    white-space: nowrap;
}

.gridContainer {
    margin-top: 30px;
}
.objAction{
    margin-top: 30px;
}
@media screen and (min-width: 960px) and (max-width: 1400px) {
    .modalDark, .modalLight{
        width: 85%;
        margin-left: 5%;
    }
    .tableModalDark,.tableModalLight{
        width: 600px;
        margin-left: auto;
        margin-right: auto;
    }
    .supplierInput{
        max-width: 33%;
        flex-basis: 33%;
    }
}
@media screen  and (min-width: 575px) and (max-width: 768px) {
    .modalDark, .modalLight{
        width: calc(100vw - 32px);
        margin: 0 auto;
        padding: 10px 10px 80px 10px;
    }
    .tableModalDark,.tableModalLight{
        width: 60vw;
        padding: 15px;
        margin: 5% auto auto auto;
    }
    .supplierInput{
        max-width: 33%;
        flex-basis: 33%;
    }
    .reportInput{
        max-width: 30% !important;
        flex-basis: 30% !important;
    }
    .close{
        top: 15px;
    }
}
@media screen  and (max-width: 575px) {
    .modalDark, .modalLight{
        width: calc(100vw - 22px) !important;
        margin: 0 auto !important;
        padding: 10px 10px 80px 10px;
    }
    .tableModalDark,.tableModalLight{
        width: calc(100vw - 30px);
        padding: 15px;
        margin: auto 0;
    }
    .tableModalDark .contacts,.tableModalLight .contacts{
        flex-direction: column;
        align-items: flex-start;
    }
    .tableModalDark .contacts p,.tableModalLight .contacts p{
        flex-direction: row;
    }
    .tableModalDark h3,.tableModalLight h3{
        word-break: break-all;
    }
    .inputs, .gridContainer{
        margin: 0 auto !important;
        width: 94% !important;
    }
    .gridContainer{
        flex-direction: column;
    }
    .gridWrapper{
        max-width: 100% !important;
        flex-basis: 100% !important;
    }
    .gridWrapper .add{
        flex-direction: column;
    }
    .gridWrapper .add{
        margin-bottom: 15px;
    }
    .userAddress, .userContact{
        max-width: 100%;
        flex-basis: 100%;
    }
    .userContact .add{
        flex-direction: column;
    }
    .userContact .add div{
        margin-bottom: 5px;
    }
    .objectAddress{
        max-width: 100%;
        flex-basis: 100%;
    }
    .supplierInput{
        max-width: 100%;
        flex-basis: 100%;
    }
    .reportInput{
        max-width: 100% !important;
        flex-basis: 100% !important;
    }
    .close{
        top: 15px;
    }
}
