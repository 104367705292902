.backgDark *,.backgLight *{
    font-family: Arial, sans-serif;
}

.backgLight * {
    color: #fff;
}

.backgDark * {
    color: #000;
}

.dark {
    background-color: #262c49;
    width: 100%;
    min-height: 100vh;
}

.light {
    background-color: #f8f8f8;
    width: 100%;
    min-height: 100vh;
}

.hLight{
    background: #fff;
}
.hDark{
    background: #10163a;
}

.light input:-webkit-autofill{
    -webkit-text-fill-color: #000 !important;
}
.dark input:-webkit-autofill{
    -webkit-text-fill-color: #fff !important;
}

.content {
    flex-grow: 1;
    padding: 0 30px 45px 30px;
    width: 50%;
    height: auto;
    margin-top: 60px;
}
.fullContent{
    width: 100%;
    padding: 0 0 15px 0;
}
.fullContent .light, .fullContent .dark{
    box-shadow: none;
}
.fullContent div{
    box-shadow: none !important;
}
.toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 1px;
    min-height: 64px;
}

.loadingScreen{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.logo{
    width: 48px;
    height: 48px;
}

.loadingBar{
    width: 160px;
    height: 2px;
    background: #cfcfcf;
    margin-top: 22px;
    position: relative;
    overflow: hidden;
}

.loadingBar::before{
    content: '';
    width: 70px;
    height: 2px;
    background: #0073b1;
    position: absolute;
    left: -40px;
    animation: bluebar 1s infinite ease;
}

@keyframes bluebar{
    50%{
        left: 96px;
    }
}

* a {
    text-decoration: none;
}

@media (max-width: 960px) {
    .content {
        margin-top: 75px;
    }
}
@media screen  and (max-width: 768px){
    .content{
        margin-top: 130px;
    }
}
@media screen  and (max-width: 575px){
    .content{
        padding: 0 0 100px 0;
        margin-top: 160px;
    }
}
.light thead th:before{
    background: rgba(0,0,0, .4) !important;
}
.light tbody td{
    border-right: 1px solid rgba(0,0,0,.1) !important;
}