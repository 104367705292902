html, body, .background {
    min-height: 100vh;
    width: 100%;
}

.background {
    background-image: url("../assets/login2.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
}

.logo {
    margin-right: 11px;
    margin-left: -10px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    color: #fff!important;
}
input:-webkit-autofill {
    -webkit-text-fill-color: #fff !important;
}