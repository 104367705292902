.aplNumber {
	font-size: 12px!important;
	border-radius: 3px;
	margin: 2px;
}

.status {
	font-size: 12px!important;
	border: 1px solid #7075FF;
	border-radius: 3px;
	margin: 2px;
	width: 80%;
	height: 20px;
	padding-top: 5px;
	align-items: center;
	font-weight: 600;
	background-color: transparent;
}

.tenderStatusNew {
	border: 1px solid #00A3D7;
	border-radius: 3px;
	font-size: 12px !important;
	width: 80%;
	height: 20px;
	margin: 3px auto;
	padding-top: 5px;
	align-items: center;
	font-weight: 600;
	background-color: transparent;
}

.tenderStatusConfirm {
	border: 1px solid #2AD43B;
	border-radius: 3px;
	font-size: 12px !important;
	width: 80%;
	height: 20px;
	margin: 3px auto;
	padding-top: 5px;
	align-items: center;
	font-weight: 600;
	background-color: transparent;
}
@media screen and (min-width: 960px) and (max-width: 1400px){
	.addTenderInput{
		max-width: 33.33333% !important;
		flex-basis: 33.33333% !important;
	}
}
@media screen and (max-width: 768px){
	.tenderFilterInput{
		max-width: 33.33333% !important;
		flex-basis: 33.33333% !important;
	}
	.addTenderInput{
		max-width: 50% !important;
		flex-basis: 50% !important;
	}
}
@media screen and (max-width: 575px){
	.tenderFilterInput{
		max-width: 100% !important;
		flex-basis: 100% !important;
	}
	.addTenderInput{
		max-width: 100% !important;
		flex-basis: 100% !important;
	}
}