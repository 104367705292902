* {
}

.light,
.dark {
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}

.light {
  background: #fff;
  border-radius: 10px;
  padding: 15px;
}

.light input {
  color: #000000 !important;
}

.dark input {
  color: #ffffff !important;
}

.dark {
  background: #10163a;
  border-radius: 10px;
  padding: 15px;
}

.light,
.dark p {
  font-size: 22px;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.autoComplete {
  width: 268px;
  height: 31px;
  margin-bottom: 20px;
  margin-right: 27px;
}

.labelLight {
  font-size: 12px !important;
  color: #000 !important;
}

.labelDark {
  font-size: 12px !important;
  color: #fff !important;
}

.filterLight,
.filterDark {
  display: flex;
}

.filterLight * {
  color: #000 !important;
}

.filterDark * {
  color: #fff !important;
}

.action {
  display: flex;
  justify-content: space-around;
}

/*********************  Modal  ************************/
.modal {
  background: #10163a;
  padding: 20px;
  color: #fff;
  width: 75%;
  --borderWidth: 1px;
  position: relative;
  border-radius: 10px;
  margin-left: 20%;
  margin-top: 5%;
}

.modal p {
  font-size: 22px;
  line-height: 27px;
  font-family: Montserrat, sans-serif;
}

.form {
  width: 100%;
}

.modal:after,
.tableModal:after {
  content: "";
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #4b74ff, #000, #fff);
  border-radius: calc(10 * var(--borderWidth));
  z-index: -1;
  animation: animatedGradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

.tableModalDark {
  background: #10163a;
  padding: 23px;
  color: #fff;
  width: 35%;
  --borderWidth: 1px;
  position: relative;
  border-radius: 10px;
  margin-top: 5%;
  margin-left: 32%;
  min-height: 118px;
}

.tableModalLight {
  background: #e5eaf1;
  padding: 23px;
  color: #fff;
  width: 35%;
  --borderWidth: 1px;
  position: relative;
  border-radius: 10px;
  margin-top: 5%;
  margin-left: 32%;
  min-height: 118px;
}

.tableModalLight * {
  color: #000 !important;
}

.tableModalDark * {
  color: #fff !important;
}

.tableModalDark h3,
.tableModalLight h3 {
  font-size: 22px;
  margin-top: -10px;
}

.modalData {
  display: block;
  font-size: 15px;
}

.modalData img {
  margin-right: 5px;
}

.close {
  position: absolute;
  top: 23px;
  right: 23px;
  cursor: pointer;
}

.modalContacts img {
  display: block;
  margin-bottom: 10px;
}

.tooltip {
  margin-right: 3px;
  font-size: 12px;
}

.modalContacts {
  position: absolute;
  right: 20px;
}

.logo {
  position: absolute;
  right: 0;
  bottom: -5%;
  overflow-x: hidden;
}

.grid {
  position: relative;
  border: 2px solid #ffffff;
  width: auto;
  height: auto;
  padding: 21px 10px;
  margin-right: 26px;
  margin-bottom: 17px;
  border-radius: 5px;
}

.grid p {
  font-size: 11px;
  opacity: 0.5;
  margin: 0 0 5px 0;
}

.grid h4 {
  margin: 5px 0 15px 0;
}

.userData {
  display: flex;
}

.userData img {
  margin-right: 5px;
}

.excel {
  font-size: 14px !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  background: #75f158;
}

.excel img {
  margin-right: 5px;
}

.exportButton {
  margin-left: auto !important;
}

@keyframes animatedGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.upload {
  background: #7075ff;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 35px;
  align-items: center;
  text-align: center;
}

.file {
  background: #7075ff;
  border: none;
  border-radius: 5px;
  color: #fff !important;
  cursor: pointer;
  font-size: 14px;
  padding: 8px 10px;
  display: flex;
  justify-content: space-around;
  height: 20px;
  align-items: center;
  text-align: center;
}

.fileUploaded {
  border: 1px solid #566ee9;
  border-radius: 5px;
  padding: 5px;
  font-size: 10px;
  display: flex;
  height: 20px;
}

.fileUploaded h5 {
  margin: 0;
}

.fileUploaded img {
  width: 20px;
  cursor: pointer;
}

.fileImgText {
  display: flex;
  align-items: center;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fileUploaded img {
  margin-right: 10px;
}

.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  position: relative;
}

.form input {
  color: #000000 !important;
  -webkit-text-fill-color: #000000 !important;
}

.formInput {
  width: calc(33.33333% - 15px);
  margin-bottom: 15px;
  height: fit-content;
  position: relative;
}

.form .formInput:nth-child(2) {
  margin-bottom: 30px;
}

.form .formInput:nth-child(2) div:nth-child(1) {
  width: 100%;
}

/*.form .formInput:nth-child(16),*/
/*.form .formInput:nth-child(18) {*/
/*    width: calc(66.66666% - 7.5px);*/
/*}*/

/*.form .formInput:nth-child(11),*/
/*.form .formInput:nth-child(12),*/
/*.form .formInput:nth-child(5),*/
/*.form .formInput:nth-child(6),*/
/*.form .formInput:nth-child(7),*/
/*.form .formInput:nth-child(8),*/
/*.form .formInput:nth-child(14),*/
/*.form .formInput:nth-child(15) {*/
/*    width: calc(16.66666% - 19px);*/
/*}*/

/*.form .formInput:nth-child(18) .plus {*/
/*    display: none;*/
/*}*/

/*.form .formInput:nth-child(17) .founderFlex div:nth-child(1),*/
/*.form .formInput:nth-child(19) .founderFlex div:nth-child(1) {*/
/*    width: calc(100% - 15px);*/
/*}*/

/*.form > .formInput:nth-child(17) {*/
/*    display: grid !important;*/
/*    grid-template-columns: 4fr 1fr;*/
/*    grid-gap: 10px;*/
/*}*/

/*.form .formInput:nth-child(17) div button {*/
/*    position: absolute;*/
/*    display: flex;*/
/*}*/

/*.form .formInput:nth-child(17) button {*/
/*    position: relative;*/
/*    display: inline;*/
/*    right: 0;*/
/*}*/

/*.form > .formInput:nth-child(22) {*/
/*    margin-top: 40px;*/
/*}*/

.form .formInput label {
  font-size: 10px !important;
}

.founderFlex {
  display: flex;
  width: 100%;
  position: relative;
}

.form .formInput .plus {
  width: fit-content;
  display: flex;
  position: relative;
  margin: auto;
}

.form .formInput .plus button {
  width: 30px;
  height: 30px;
  right: 0;
  padding: 0;
  position: relative;
  margin-left: 5px;
}

/*.form > .formInput:nth-child(23) {*/
/*    margin-left: auto;*/
/*    margin-top: -126px;*/
/*}*/

/*.form .formInput:last-child{*/
/*  width: calc(66.66666% - 10px);*/
/*}*/
.formGroup {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 30px;
}

.formGroup .formInput {
  display: flex;
  margin-bottom: 5px;
  position: relative;
}

.formGroup .textareaTitle {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.formGroup .formInput:nth-child(2n) {
  width: calc(33.33333% - 15px);
  margin-bottom: 0;
}

.formGroup .formInput:nth-child(2n) > div:nth-child(1) {
  width: calc(100% - 55px);
}

.formGroup .formInput:nth-child(2n) > div:nth-child(1) > div {
  width: 100%;
}

.formGroup .formInput button {
  position: relative;
  right: unset;
}

.formGroup .formInput:nth-child(2n) button {
  width: 40px;
  height: 30px;
  margin-left: auto;
}

.formGroup .formInput:nth-child(2n + 1) {
  width: calc(66.66666% - 10px);
}

.formGroup .formInput:nth-child(2n + 1) > div:nth-child(1) {
  width: calc(66.66666% - 15px);
}

.formGroup .formInput input {
  padding: 8px 10px;
}

.formGroup .formInput fieldset {
  height: 30px;
  top: -3px;
}

.formGroup .formInput .buttons {
  margin-left: 15px;
  display: flex;
  width: fit-content;
}

.formGroup .formInput .buttons button {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  position: relative !important;
  right: unset;
}

.formGroup .formInput .buttons button:first-child {
  margin-right: 10px;
}

.formGroup .label {
  width: 33.33333%;
}

.formGroup .label:first-child {
  width: 100%;
  font-size: 16px;
}

.formGroup .label:last-child {
  padding-left: 10px;
}

.formInput button {
  position: absolute;
  right: 10px;
}

.formInput > div,
.formInput input {
  width: 100%;
}

/*.formInput:nth-child(2) div div {*/
/*  width: calc(100% - 12px);*/
/*}*/

.actionModal {
  display: flex;
  justify-content: flex-end;
}

.actionModal button:last-child {
  margin-right: 0;
}

.textareaForm {
  width: calc(66.66666% - 7px);
  display: flex;
  flex-direction: column;
  position: relative;
}

.textareaForm textarea {
  height: 40px;
}

.textareaForm .infoSb {
  height: 113px;
}

/*.formInput:nth-child(14){*/
/*    width: calc(66.66666% - 7px);*/
/*}*/

/*.textareaForm:nth-child(2){*/
/*    width: calc(33.33333% - 15px);*/
/*}*/

/*.textareaForm:last-child textarea{*/
/*    height: 80px;*/
/*}*/

.textareaTitle {
  color: #ffffff;
  font-size: 12px;
  padding-bottom: 8px;
  padding-top: 15px;
  display: block;
}

.statusColors {
  text-align: left;
}

.typeColor {
  display: flex;
  align-items: center;
}

.typeColor span {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 3px;
}

.green {
  background: green;
}

.red {
  background: red;
}

.yellow {
  background: yellow;
}

.positive,
.doubtful,
.risks,
.cashOut,
.noInList {
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin: 2px auto;
  padding: 6px 5px;
  border: 1px solid #000000;
  color: #ffffff;
  white-space: nowrap;
  width: fit-content;
}

.positive {
  border: 1px solid green;
  background: green;
}

.doubtful {
  border: 1px solid red;
  background: red;
}

.risks {
  border: 1px solid #ff8d00f5;
  background: #ff8d00f5;
}

.cashOut {
  background: red;
  border: 1px solid red;
}

.noInList {
  border: 1px solid #00a3d7;
  background: #00a3d7;
}

.subRow {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.subRow span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  max-height: 40px;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  color: #c2c6dc;
  max-width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 10px;
}

.subRow span b {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: unset;
}

.light .subRow span {
  border-bottom: 1px solid #c4c4c4;
  color: #000000;
}

.subRow span:last-child {
  border-bottom: none;
}

.titleActivity {
  display: inline;
  padding-right: 10px;
}

.modalWarning {
  width: 30vw;
  height: fit-content;
  margin: auto;
  border-radius: 40px;
}

.paper {
  background: #262c49;
  padding: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalWarning p {
  color: #ffffff;
  font-size: 16px;
}

.captcha {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.captchaImg {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 0;
}

.captchaImg img {
  width: auto;
}

.reloadImg {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 10px;
}

.captchaInput {
  display: flex;
  align-items: center;
}

.captchaInput input {
  height: 25px;
  outline: none;
  background: none;
  border: 0.5px solid #fff;
  border-radius: 4px;
  color: #fff;
  padding-left: 10px;
}

.captchaInput .lightInput {
  border: 0.5px solid #000;
}

.formInput .cnrColor {
  width: 42px;
  height: 32px;
  border-radius: 5px;
  position: absolute;
  right: 2px;
  top: 1px;
}
.selectWrapper {
  margin-bottom: 15px;
}
.select {
  appearance: none;
  background-color: transparent;
  padding: 0 1em 0 0.5rem;
  margin: 0;
  width: 100px;
  height: 30px;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  z-index: 1;
  outline: none;
  grid-area: select;
}
.select-ms-expand {
  display: none;
}

.select[multiple] {
  padding-right: 0;
  height: 6rem;
}
.select option {
  white-space: normal;
  outline-color: blue;
  color: #000 !important;
}

.fileImgText{
  color: #fff;
}
.light .fileImgText{
  color: #000;
}

@media screen and (max-width: 768px) {
  .formInput {
    width: calc(50% - 5px);
  }

  .form .formInput:nth-child(15),
  .form .formInput:nth-child(17),
  .form .formInput:nth-child(19) {
    width: calc(50% - 10px);
  }

  .form .formInput:last-child {
    margin: 5px auto;
  }

  .actionModal {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 575px) {
  .formInput {
    width: 98%;
    margin: 5px auto;
  }

  .form .formInput:nth-child(2) {
    margin-bottom: 10px;
  }

  .form .formInput:nth-child(2) div:nth-child(1) {
    width: 66%;
  }

  .form .formInput:nth-child(15),
  .form .formInput:nth-child(17),
  .form .formInput:nth-child(19) {
    width: 98%;
  }

  .actionModal {
    margin-bottom: 100px;
  }
}
