* {
}

.light, .dark{
    box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);
}

.light {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 15px;
}

.dark {
    background: #10163a;
    border-radius: 10px;
    padding: 15px;
}

.light p, .dark p {
    font-size: 22px;
    line-height: 22px;
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top div p {
    font-size: 14px;
    font-weight: lighter;
}


.autoComplete {
    width: 268px;
    height: 31px;
    margin-bottom: 20px;
    margin-right: 27px;
}

.labelLight {
    font-size: 12px !important;
    color: #000 !important;
}

.labelDark {
    font-size: 12px !important;
    color: #fff !important;
}

.input {
    font-size: 12px !important;
    color: #fff !important;
    border-color: #fff !important;
}

.filterPayCal {
    margin-top: 20px !important;
    display: flex;
}

.filterLight *{
    color: #000 !important;
}

.filterDark *{
    color: #fff !important;
}

.table {
    margin-top: 20px !important;
}

.action {
    display: flex;
    justify-content: space-around;
}

.action1 {
    text-align: right;
}

.copyButton {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

/*********************  Modal  ************************/
.modalDark {
    background: radial-gradient(92.74% 92.74% at 50% 7.26%, #161616 0%, #000000 100%);
    padding: 20px;
    color: #fff;
    width: 50%;
    --borderWidth: 1px;
    position: relative;
    border-radius: 10px;
    margin-left: 25%;
    margin-top: 5%;
}

.modalLight {
    background: #E5EAF1;
    padding: 20px;
    color: #fff;
    width: 50%;
    --borderWidth: 1px;
    position: relative;
    border-radius: 10px;
    margin-left: 25%;
    margin-top: 5%;
}

.modalDark * {
    color: #fff !important;
}

.modalLight * {
    color: #000 !important;
}

.modalLight, .modalDark p {
    font-size: 22px;
    /*font-family: Montserrat, sans-serif;*/
}

.form {
    width: 100%;
}

.modal:after, .tableModal:after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(60deg, #4B74FF, #000, #fff);
    border-radius: calc(10 * var(--borderWidth));
    z-index: -1;
    animation: animatedGradient 3s ease alternate infinite;
    background-size: 300% 300%;
}

.tableModalDark {
    background: #10163a;
    padding: 23px;
    color: #fff;
    width: 35%;
    --borderWidth: 1px;
    position: relative;
    border-radius: 10px;
    margin-top: 5%;
    margin-left: 32%;
    min-height: 118px;
}

.tableModalLight {
    background: #FFFFFF;
    padding: 23px;
    color: #fff;
    width: 35%;
    --borderWidth: 1px;
    position: relative;
    border-radius: 10px;
    margin-top: 5%;
    margin-left: 32%;
    min-height: 118px;
}

.tableModalLight h3, .tableModalDark h3 {
    font-size: 22px;
    margin-top: -10px;
}

.tableModalDark * {
    color: #fff !important;
}

.tableModalLight * {
    color: #000 !important;
}

.modalData {
    display: block;
    font-size: 15px;
}

.modalData img {
    margin-right: 5px;
}

.close {
    position: absolute;
    top: 23px;
    right: 23px;
    cursor: pointer;
}

.tableModalLight .close{
    filter: invert(1);
}

.modalContacts img {
    display: block;
    margin-bottom: 10px;
}

.modalContacts {
    position: absolute;
    right: 20px;
}

.logo {
    position: absolute;
    right: 0;
    bottom: -5%;
    overflow-x: hidden;
}

.grid {
    position: relative;
    border: 2px solid #FFFFFF;
    width: auto;
    height: auto;
    padding: 21px 10px;
    margin-right: 26px;
    margin-bottom: 17px;
    border-radius: 5px;
}

.grid p {
    font-size: 11px;
    opacity: 0.5;
    margin: 0 0 5px 0;
}

.muiGrid-item {
    padding: 17px;
}

.grid h4 {
    margin: 5px 0 15px 0;
}

.userData {
    display: flex;
}

.userData img {
    margin-right: 5px;
}

.fullName {
    font-size: 12px !important;
    margin: 0;
    color: #9FA3FF;
}

.tooltip {
    margin-right: 3px;
    font-size: 12px;
}

.tooltipHeader {
    font-size: 16px;
    margin: 4px 10px 4px 10px;
}

.tooltipBody p {
    font-size: 12px;
    margin: 5px 15px 10px 15px;

}

.tooltipBody h5 {
    font-size: 12px;
    margin: 5px 15px;
    font-weight: lighter;
}

.xy {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}

Button div {
    padding: 0;
    margin: 0;

}

.paymentHeader p {
    font-size: 15px;
    font-weight: lighter;
    padding: 3px 4px;
    margin: 0;
}

.paymentHeader h5 {
    font-size: 15px;
    font-weight: bold;
    padding: 3px 4px;
    margin: 0;
}

.paymentHeader {
    border-bottom: 1px solid white;
}

.hr {
    opacity: 0.5;
    border: 1px solid #E0F8FF;
    color: #E0F8FF;
    margin: 12px 0;
}

.statusOn {
    width: auto;
    height: 20px;
    border-radius: 3px;
    background: #00A3D7;
    margin: 0;
    font-size: 16px !important;
    padding: 5px;
}

input[type=radio] {
    color: white !important;
    border: 1px solid white !important;
}

.search {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkbox {
    margin-left: 10px;
}

@keyframes animatedGradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
@media screen and (min-width: 960px) and (max-width: 1400px){
    .tableModalLight, .tableModalDark{
        width: 60% !important;
        margin-left: auto !important;
        margin-right: auto;
    }
}
@media screen  and (min-width: 575px) and (max-width: 768px){
    .tableModalLight, .tableModalDark{
        width: 80% !important;
        margin-left: auto !important;
        margin-right: auto;
    }
}
@media screen  and (max-width: 575px){
    .tableModalLight, .tableModalDark{
        width: calc(100vw - 30px) !important;
        margin-left: auto !important;
        margin-right: auto;
        padding: 10px 10px 80px 10px;
    }
    .tableModalLight h3, .tableModalDark h3{
        margin: 25px 0;
    }
    .paymentGridWrapper{
        max-width: 100%;
        flex-basis: 100%;
    }
    .grid{
        width: calc(100% - 34px);
        padding: 15px;
        margin: 0 0 15px 0;
    }
}