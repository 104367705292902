.horizontal-menu {
  overflow: hidden;
}

.scroll-currency {
  position: relative;
}

.scroll-currency .date {
  display: none;
}
.scroll-currency .date:first-child {
  display: inline;
}

.scroll-currency .title {
  height: 100%;
  display: flex;
  align-items: center;
  width: fit-content;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  background: #10163a;
  z-index: 1;
  padding-right: 30px;
  font-size: 12px;
}

.scroll-currency .title .padding {
  padding-left: 5px;
  font-size: 14px;
}

.scroll-currency .title.title-light {
  background: #ffffff;
}

.currency {
  position: relative;
  display: flex;
}
.organizations-list {
  display: flex;
  width: 225px;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0 auto 70px auto;
}
.organizations-list .title {
  text-align: center;
  padding-top: 10px;
  font-weight: 600;
}
.organizations-list .stop {
  position: absolute;
  z-index: 1;
}
.organizations-list .figure {
  margin: 0;
  width: 186px;
  height: 200px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
}
.marquee {
  width: fit-content;
  height: 50px;
  position: relative;
  display: flex;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -moz-animation: marquee 30s linear infinite;
  -webkit-animation: marquee 30s linear infinite;
  animation: marquee 30s linear infinite;
}
.marquee-uzex {
  -moz-animation: marquee 100s linear infinite;
  -webkit-animation: marquee 100s linear infinite;
  animation: marquee 100s linear infinite;
}
.marquee-org {
  -moz-animation: marquee 4000s linear infinite;
  -webkit-animation: marquee 4000s linear infinite;
  animation: marquee 4000s linear infinite;
  margin-bottom: 70px;
}
.marquee.marquee-org p {
  font-size: 16px;
  text-align: center;
  margin: 0 5px;
}
.currency:hover .marquee,
.organizations-list:hover .marquee {
  animation-play-state: paused;
}

.marquee img {
  width: 15px;
}

.marquee p {
  white-space: nowrap;
  font-size: 10px;
}
@keyframes marquee {
  0% {
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.scroll-x .horizontal-menu {
  width: 100%;
}
.ex-title strong,
.ex-title b {
  font-size: 14px;
  font-weight: 600;
}
.ex-title b {
  padding-left: 3px;
}
.difference {
  padding-left: 5px;
}
.red {
  color: red !important;
  font-size: 13px;
}
.green {
  color: green !important;
  font-size: 13px;
}
.arrow {
  border: solid red;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  margin-left: 5px;
}
.green .arrow {
  border: solid green;
  border-width: 0 2px 2px 0;
}
.increase {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.decrease {
  transform: rotate(45deg) translateY(-4px);
  -webkit-transform: rotate(45deg) translateY(-4px);
}

@media screen and (min-width: 960px) and (max-width: 1440px) {
  .scroll-currency .title {
    display: none;
  }
}

/*.increase, .decrease{*/
/*    */
/*}*/
