.light, .dark{
    box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);
}
.dark {
    background: #10163a;
    border-radius: 10px;
    padding: 15px;
}

.light {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 15px;
}

.dark, .light p {
    font-size: 22px;
    line-height: 22px;
}

.dark, .light{
    display: flex;
    flex-wrap: wrap;
}

.top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tableType {
    display: flex;
    flex-direction: column;
}
.roleTable{
    width: 100%;
}
.tableType:nth-child(2){
    width: 30%;
}
.tableType:last-child{
    width: 70%;
}

.parent {
    width: auto;
    margin-right: 2%;
    border: 2px solid #545bff;
    padding: 5px;
    border-radius: 7px;
}

.child {
    width: calc(100% - 10px);
    border: 2px solid #545bff;
    padding: 5px;
    border-radius: 7px;
}

.divider {
    min-height: 100%;
    opacity: 0.6;
    border: 1px solid #E0F8FF;
    margin-left: -1%;
    margin-right: 1%;
}

.TakeAction {
    display: flex;
    justify-content: space-evenly;
}

/*.dark .TakeAction img{*/
/*    filter: invert(1);*/
/*}*/
@media screen and (min-width: 960px) and (max-width: 1400px){
    .tableType:nth-child(2){
        width: 40%;
    }
    .tableType:last-child{
        width: 60%;
    }
}
@media screen and (max-width: 768px){
    .tableType{
        width: 50% !important;
    }
}
@media screen and (max-width: 575px){
    .tableType{
        width: 100% !important;
    }
}