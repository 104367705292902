.react-emoji{
    align-items: flex-start;
}
.react-input-emoji--container {
    margin: 15px 0;
    border-radius: 0 !important;
}
.react-emoji-picker--wrapper{
    bottom: unset;
    top: 70px;
}
.react-input-emoji--placeholder{
    left: 10px;
    top: 10px;
    bottom: unset;
}
.react-input-emoji--button{
    margin-top: 20px;
}
.react-input-emoji--input{
    min-height: 150px;
    max-height: 300px;
}
.dark-telegram-container .react-input-emoji--input {
    background: #262c49;
}
.dark-telegram-container .react-input-emoji--container{
    border-color: #989CEF !important;
}
.dark-telegram-container .react-emoji-picker--wrapper *{
    color: #000;
}