* {
}

.light {
  background: #ffffff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);
}

.dark {
  background: #10163a;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);
}

.light,
.dark p {
  font-size: 22px;
  /*line-height: 22px;*/
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.autoComplete {
  width: 268px;
  height: 31px;
  margin-bottom: 20px;
  margin-right: 27px;
}

.labelLight {
  font-size: 12px !important;
  color: #000 !important;
}

.labelDark {
  font-size: 12px !important;
  color: #fff !important;
}

.filterLight,
.filterDark {
  display: flex;
}

.filterLight * {
  color: #000 !important;
}

.filterDark * {
  color: #fff !important;
}

.action {
  display: flex;
  justify-content: space-around;
}

/*********************  Modal  ************************/
.modal {
  background: radial-gradient(
    92.74% 92.74% at 50% 7.26%,
    #161616 0%,
    #000000 100%
  );
  padding: 20px;
  color: #fff;
  width: 75%;
  --borderWidth: 1px;
  position: relative;
  border-radius: 10px;
  margin-left: 20%;
  margin-top: 5%;
}

.modal p {
  font-size: 22px;
  line-height: 27px;
  font-family: Montserrat, sans-serif;
}

.form {
  width: 100%;
}

.modal:after,
.tableModal:after {
  content: "";
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #4b74ff, #000, #fff);
  border-radius: calc(10 * var(--borderWidth));
  z-index: -1;
  animation: animatedGradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

.tableModalDark {
  background: #10163a;
  padding: 23px;
  color: #fff;
  width: 35%;
  --borderWidth: 1px;
  position: relative;
  border-radius: 10px;
  margin-top: 5%;
  margin-left: 32%;
  min-height: 118px;
}

.tableModalLight {
  background: #ffffff;
  padding: 23px;
  color: #fff;
  width: 35%;
  --borderWidth: 1px;
  position: relative;
  border-radius: 10px;
  margin-top: 5%;
  margin-left: 32%;
  min-height: 118px;
}

.tableModalLight * {
  color: #000 !important;
}

.tableModalDark * {
  color: #fff !important;
}

.tableModalLight .close {
  filter: invert(1);
}

.tableModalLight button {
  color: #ffffff !important;
}

.tableModalDark h3,
.tableModalLight h3 {
  font-size: 22px;
  margin-top: -10px;
}

.modalData {
  display: block;
  font-size: 15px;
}

.modalData img {
  margin-right: 5px;
}

.close {
  position: absolute;
  top: 23px;
  right: 23px;
  cursor: pointer;
}

.modalContacts img {
  display: block;
  margin-bottom: 10px;
}

.tooltip {
  margin-right: 3px;
  font-size: 12px;
}

.modalContacts {
  position: absolute;
  right: 20px;
}

.logo {
  position: absolute;
  right: 0;
  bottom: -5%;
  overflow-x: hidden;
}

.grid {
  position: relative;
  border: 2px solid #ffffff;
  width: auto;
  height: auto;
  padding: 21px 10px;
  margin-right: 26px;
  margin-bottom: 17px;
  border-radius: 5px;
}

.grid p {
  font-size: 11px;
  opacity: 0.5;
  margin: 0 0 5px 0;
}

.grid h4 {
  margin: 5px 0 15px 0;
}

.userData {
  display: flex;
}

.userData img {
  margin-right: 5px;
}

.tableModalLight .userData img {
  filter: invert(1);
}

.excel {
  font-size: 14px !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  background: #7367f0;
  color: #ffffff !important;
}

.excel img {
  margin-right: 5px;
}

.exportButton {
  margin-left: auto !important;
}

@keyframes animatedGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@media screen and (min-width: 575px) and (max-width: 768px) {
  .tableModalLight,
  .tableModalDark {
    width: 60%;
    margin-left: 20%;
  }
  .exportButton {
    max-width: 100px !important;
    flex-basis: 100px !important;
  }
}
@media screen and (max-width: 575px) {
  .tableModalLight,
  .tableModalDark {
    margin: 5% 0 0 0;
    width: calc(100vw - 20px);
    padding: 10px 10px 45px 10px;
  }
  .tableModalLight h3,
  .tableModalDark h3 {
    margin-top: 30px;
  }
  .grid {
    padding: 10px;
    margin-right: 10px;
    margin-top: 20px;
    border: none;
  }
  .grid h4 {
    word-break: break-all;
  }
  .exportButton {
    max-width: 100px !important;
    flex-basis: 100px !important;
  }
}
