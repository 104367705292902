.chart {
    margin-top: 10px;
}

.light {
    background: #E5EAF1;
    border-radius: 10px;
    padding: 20px;
    /*margin: 0 15px 15px -3px;*/
}

.dark {
    background: #000;
    border-radius: 10px;
    padding: 20px;
    /*margin: 0 0px 15px -3px;*/
}
