.flexible-modal {
    position: absolute;
    z-index: 999999 !important;
    background: transparent !important;
    border: none !important;
    width: 55vw !important;
    height: fit-content !important;
    min-height: 400px !important;
    max-height: 80vh !important;
}
.flexible-modal-mask {
    position: fixed;
    height: 100%;
    background: rgba(55, 55, 55, 0.6);
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: 999;
}
.flexible-modal-resizer {
    position:absolute;
    right:0;
    bottom:0;
    cursor:se-resize;
    margin:5px;
    border-bottom: solid 2px #333;
    border-right: solid 2px #333;
    display: none;
}
.flexible-modal-drag-area{
    background: rgba(22, 22, 333, 0.2);
    width: 100% !important;
    height: 35px !important;
    position:absolute;
    right:0;
    top:0;
    left: 0;
    cursor:move;
}
.flexible-modal-drag-area-left,.flexible-modal-drag-area-right,.flexible-modal-drag-area-bottom{
    display: none;
}
.flexible-modal .drag-modal{
    margin: 0 !important;
    width: fit-content !important;
    min-width: 20% !important;
    max-width: calc(100% - 60px) !important;
    min-height: 180px !important;
    padding: 0 30px 30px 30px;
    display: flex;
    flex-direction: column;
    border-radius: 0;
}
.flexible-modal .drag-modal:after{
    border-radius: 0;
}
/*.flexible-modal .setting-modal{*/
/*    width: 320px !important;*/
/*}*/
.flexible-modal .verModal{
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
    max-height: fit-content;
    overflow: unset;
}
.flexible-modal .inner-checkbox-modal{
    width: 45vw;
}
.modal-light .close{
    filter: invert(1);
}
.modal-light input{
    color: #000;
}
.modal-dark *{
    color: #fff !important;
}
.drag-modal h3{
    margin-top: 10px !important;
}
.drag-modal .close{
    top: 5px;
    right: 10px;
    z-index: 2;
}

.flexible-modal .setting-modal .close{
    right: 0;
}
.flexible-modal .modal-buttons{
    display: flex;
    justify-content: flex-end;
}
.flexible-modal .modal-header-title{
    margin-top: 4px !important;
    font-size: 16px;
    z-index: 1;
    width: fit-content;
}

.add-modal .close{
    position: absolute;
    cursor: pointer;
 }
.flexible-modal .add-modal{
    width: 100% !important;
}