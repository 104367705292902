.map {
    width: 100%;
    height: 200px!important;
    border: 2px solid #fcc600;
    border-radius: 2px;
}

.logo {
    display: flex;
    align-items: center;
    font-size: 12px;
}

.logo img {
    width: 45px;
    border-radius: 30px;
    margin-right: 10px;
}

.location {
    width: 350px;
    text-align: center;
    position: absolute;
    margin-top: -125px;
    margin-left: 250px;
    font-weight: bold;
    font-size: 15px;
    color: #000;
}

@media (max-width: 900px) {
    .map {
        width: 300px;
        height: 500px;
        margin-bottom: -20px;
    }
    .approve {
        margin-top: -60px;
        margin-left: 70px;
        text-align: center;
    }
    .location {
        margin-left: 0px;
        font-size: 12px;
        width: 300px;
    }
    .logo {
        margin-top: 20px;
        position: absolute;
        outline: none;
    }
}